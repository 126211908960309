import { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../ui/button";
import { cn } from "../../lib/utils";
import { CircleCheck, ChevronRight, FileText, XCircle, Paperclip, Loader2 } from "lucide-react";
import { withRequiredAuthInfo, UserClass } from "@propelauth/react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import DocumentPreviewDialog from "../DocumentPreviewDialog";
import { useNodeApi } from "../../hooks/useNodeApi";
import { WorkflowStep as ApiWorkflowStep, Citation, CitationWithState, getWorkflowV1, WorkflowExecutionLogResponse, UserDocumentUploadRequest, UserDocument } from "../../services/api";
import { usePolling } from "../../lib/usePolling";
import { WorkflowExecutionStatus } from "../../services/api";
import { toast } from "react-hot-toast";
import { PromptInput } from "../core/PromptInput";
import faiLogo from '../../assets/fai-logo-short.svg';
import { Canvas } from "../canvas/Canvas";
import { GuidelinesDetail } from "../uwaudit/GuidelinesDetail";
import { UwAuditDetail } from "../uwaudit/UwAuditDetail";
import { PDFPreviewSlider } from "../compare/PDFPreviewSlider";
import { produce } from "immer";
import { useSharePoint } from '../../lib/sharepoint';
import { CompareDetail } from "../compare/CompareDetail";
import { FillExcelDetail } from "../fillexcel/FillExcelDetail";
import { ExtractDetail } from "../extract/ExtractDetail";
import { EmailDetail } from "../submissions/EmailDetail";
import { markdownComponents } from '../../lib/markdownComponents';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

interface WorkflowPresentationProps {
  executionId: string;
  accessToken: string;
  userClass: UserClass;
  isInCanvas?: boolean;
}

interface AnswerSnippet {
  text: string;
  document_id: string;
  bbox_values: Citation[];
}

interface SignatureInfo {
  document_name: string;
  signatures: Array<{
    signature_type?: string;
    is_on_signature_line?: boolean;
    has_date_on_date_line?: boolean;
    signer_name?: string;
    signature_date?: string;
    is_verified_digital?: boolean | null;
    is_scanned_handwritten?: boolean;
  }>;
}

interface UIWorkflowStep {
  id: string;
  title: string;
  stepType: string;
  status?: string;
  documentTypes?: string[];
  submissionType?: string;
  auditLink?: string;
  compareLink?: string;
  output?: {
    link_to_excel_log?: string;
    link_to_compare_details?: string;
    link_to_extract_log?: string;
    email_body?: string;
  };
  documents?: {
    name: string;
    documentId: string;
    fileType?: string;
    className?: string;
  }[];
  guidelines?: string[];
  titles?: string[];
  question?: string;
  answerSnippets?: AnswerSnippet[];
  signatureAnalysis?: SignatureInfo[];
}

interface Message {
  content: string;
  role: 'user' | 'assistant';
  timestamp: Date;
  icon?: React.ReactNode;
  attachments?: UserDocument[];
  citations?: {
    document_id: string;
    bbox_values: Citation[];
  }[];
}

interface CitationWithDocId extends CitationWithState {
  documentId: string;
}

interface DocumentReference {
  id: string;
  filename: string;
  startIndex: number;
  endIndex: number;
}

interface ParsedMessage {
  rawContent: string;
  references: DocumentReference[];
}

interface MessageContentProps {
  content: string;
  role: 'user' | 'assistant';
  citations?: { document_id: string; bbox_values: Citation[] }[];
}

const transformStepToUIFormat = (step: ApiWorkflowStep): UIWorkflowStep => {
  if (step.step_type === 'classify_submission_type') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      submissionType: step.output?.result as string
    };
  }

  if (step.step_type === 'classify_documents' && step.output?.result) {
    const documents: {
      name: string;
      documentId: string;
      fileType?: string;
      className: string;
    }[] = [];
    let documentTypes: string[] = [];

    documentTypes = Object.keys(step.output.result);

    Object.entries(step.output.result).forEach(([className, docs]) => {
      if (Array.isArray(docs)) {
        const transformedDocs = docs.map(doc => ({
          name: doc.filename,
          documentId: doc.user_document_id,
          fileType: doc.filename.split('.').pop()?.toLowerCase(),
          className
        }));
        documents.push(...transformedDocs);
      }
    });

    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      documents: documents.length > 0 ? documents : undefined,
      documentTypes: documentTypes.length > 0 ? documentTypes : undefined,
    };
  }

  if (step.step_type === 'extract_guidelines') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      guidelines: step.output?.guidelines,
      titles: step.output?.titles
    };
  }

  if (step.step_type === 'check_guidelines') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      auditLink: step.output?.link_to_audit_details
    };
  }

  if (step.step_type === 'compare_with_schema') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      compareLink: step.output?.link_to_compare_details
    };
  }

  if (step.step_type === 'qa') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      question: step.input?.question,
      answerSnippets: step.output?.answer_snippets_with_bbox_values?.map(snippet => ({
        text: snippet.text,
        document_id: snippet.document_id,
        bbox_values: snippet.bbox_values.map(bbox => ({
          type: bbox.type,
          bbox: bbox.bbox,
          content: bbox.content || '',
          image_url: bbox.image_url || null
        }))
      }))
    };
  }

  if (step.step_type === 'sov_mapping') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      output: {
        link_to_excel_log: step.output?.link_to_excel_log
      }
    };
  }

  if (step.step_type === 'extract_from_text' ||
      step.step_type === 'extract_from_document' ||
      step.step_type === 'extract_rows_from_multiple_documents') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      output: {
        link_to_extract_log: step.output?.link_to_extract_log
      }
    };
  }

  if (step.step_type === 'email') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      output: {
        email_body: step.output?.email_body
      }
    };
  }

  if (step.step_type === 'signature_analysis') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      signatureAnalysis: step.output?.result as SignatureInfo[]
    };
  }

  return {
    id: step.step_type,
    title: step.title,
    stepType: step.step_type
  };
};

const parseMessageReferences = (content: string): ParsedMessage => {
  const references: DocumentReference[] = [];
  const pattern = /@([^<]+)<document_start>([^<]+)<document_end>/g;

  const allMatches: {
    filename: string,
    docId: string,
    index: number,
    length: number
  }[] = [];

  let match;
  while ((match = pattern.exec(content)) !== null) {
    const [fullMatch, filename, docId] = match;

    const displayName = filename.length > 40
      ? filename.substring(0, 37) + '...'
      : filename;

    allMatches.push({
      filename: displayName.trim(),
      docId,
      index: match.index,
      length: fullMatch.length
    });
  }

  allMatches.sort((a, b) => a.index - b.index);

  let lastIndex = 0;
  let processedContent = '';

  allMatches.forEach((match) => {
    processedContent += content.slice(lastIndex, match.index);

    references.push({
      id: match.docId,
      filename: match.filename,
      startIndex: processedContent.length,
      endIndex: processedContent.length + match.filename.length + 1
    });

    processedContent += `@${match.filename}`;
    lastIndex = match.index + match.length;
  });

  processedContent += content.slice(lastIndex);

  return {
    rawContent: processedContent,
    references
  };
};

const renderMessageContent = ({ content, role, citations }: MessageContentProps) => {
  if (role === 'assistant' && citations) {
    let citationCounter = 1;
    return (
      <div className="inline leading-normal">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            ...markdownComponents,
            p: ({ children }) => (
              <span className="inline whitespace-pre-wrap">{children}</span>
            ),
            ul: ({ children }) => (
              <ul className="block list-disc pl-6 space-y-2 w-full">{children}</ul>
            ),
            ol: ({ children }) => (
              <ol className="block list-decimal pl-6 space-y-2 w-full">{children}</ol>
            ),
            li: ({ children }) => (
              <li className="text-sm leading-relaxed">{children}</li>
            ),
          }}
          className="prose prose-sm max-w-none inline [&>*:not(table,ul,ol)]:inline [&>*:not(table,ul,ol)]:m-0 [&>*:not(table,ul,ol)]:p-0 [&>ol:first-child]:-mt-1 [&>ul:first-child]:-mt-1"
        >
          {content}
        </ReactMarkdown>
        <span className="inline-flex items-center gap-0.5 ml-1">
          {citations.map((doc, docIndex) =>
            doc.bbox_values.map((bbox, bboxIndex) => {
              const currentCitation = citationCounter++;
              return (
                <button
                  key={`citation-${docIndex}-${bboxIndex}`}
                  type="button"
                  className="citation-link inline-flex items-center justify-center rounded-full bg-gray-200 w-[18px] h-[18px] text-xs font-medium text-gray-700 hover:bg-gray-300 transition-colors cursor-pointer no-underline align-baseline"
                  data-citation-index={bboxIndex}
                  data-document-id={doc.document_id}
                  data-citation-content={bbox.content}
                  aria-label={`Citation ${currentCitation}`}
                >
                  {currentCitation}
                </button>
              );
            })
          )}
        </span>
      </div>
    );
  }

  if (role === 'user') {
    const { rawContent, references } = parseMessageReferences(content);

    if (references.length === 0) {
      return <span className="whitespace-pre-wrap">{content}</span>;
    }

    const parts: JSX.Element[] = [];
    let lastIndex = 0;

    references.forEach((ref, index) => {
      if (ref.startIndex > lastIndex) {
        parts.push(
          <span key={`text-${index}`} className="whitespace-pre-wrap">
            {rawContent.slice(lastIndex, ref.startIndex)}
          </span>
        );
      }

      parts.push(
        <span
          key={`ref-${ref.id}`}
          className="inline bg-purple-200/50 text-purple-900 rounded-md px-1"
        >
          <span className="opacity-50">@</span>
          {ref.filename}
        </span>
      );

      lastIndex = ref.startIndex + `@${ref.filename}`.length;
    });

    if (lastIndex < rawContent.length) {
      parts.push(
        <span key="text-end" className="whitespace-pre-wrap">
          {rawContent.slice(lastIndex)}
        </span>
      );
    }

    return <>{parts}</>;
  }

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={markdownComponents}
      className="prose prose-sm max-w-none"
    >
      {content}
    </ReactMarkdown>
  );
};

const hasDetailView = (stepType: string): boolean => {
  return [
    'classify_submission_type',
    'classify_documents',
    'extract_guidelines',
    'check_guidelines',
    'compare_with_schema',
    'qa',
    'sov_mapping',
    'extract_from_text',
    'extract_from_document',
    'extract_rows_from_multiple_documents',
    'email',
    'signature_analysis'
  ].includes(stepType);
};

const WorkflowPresentation = ({
  executionId,
  accessToken,
  userClass,
  isInCanvas: initialIsInCanvas = false
}: WorkflowPresentationProps) => {
  const org = userClass?.getOrgs()[0];
  const navigate = useNavigate();
  const [workflowName, setWorkflowName] = useState<string>("");
  const [workflowId, setWorkflowId] = useState<string>("");
  const [steps, setSteps] = useState<UIWorkflowStep[]>([]);
  const [executionLog, setExecutionLog] = useState<WorkflowExecutionLogResponse | null>(null);
  const [previewDocument, setPreviewDocument] = useState<{ url: string, fileType?: string } | null>(null);
  const [selectedDocType, setSelectedDocType] = useState<string>("");
  const selectedDocTypeRef = useRef<string>("");
  const { fetchWorkflowExecutionLog, getPdfUrl, fetchWorkkflowExecutionAskAI, uploadDocument, deleteDocument, fetchUserDocument } = useNodeApi(accessToken);
  const lastPollTimeRef = useRef(0);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [expandedSteps, setExpandedSteps] = useState<{ [key: string]: boolean }>({});
  const [rightContent, setRightContent] = useState<React.ReactNode | null>(null);
  const [isInCanvas, setIsInCanvas] = useState(initialIsInCanvas);
  const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isPdfSliderOpen, setIsPdfSliderOpen] = useState(false);
  const [selectedCitations, setSelectedCitations] = useState<CitationWithDocId[]>([]);
  const [pdfUrls, setPdfUrls] = useState<Map<string, string>>(new Map());
  const [uploadedDocuments, setUploadedDocuments] = useState<UserDocument[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isWorkflowNameLoading, setIsWorkflowNameLoading] = useState(true);
  const [isStepsLoading, setIsStepsLoading] = useState(true);
  const [currentFileType, setCurrentFileType] = useState<'pdf' | 'excel'>('pdf');
  const [filteredDocumentIds, setFilteredDocumentIds] = useState<string[]>([]);
  const [shouldInvalidateCache, setShouldInvalidateCache] = useState(false);

  const { selectAndUploadFile, isLoading: isSharePointLoading } = useSharePoint(
    uploadDocument,
    setUploadedDocuments,
    process.env.REACT_APP_SHAREPOINT_CLIENT_ID || '',
    process.env.REACT_APP_TENANT_ID || '',
    org?.orgMetadata["SHAREPOINT_HOSTNAME"] || ''
  );

  const isWorkflowComplete = useCallback((log: WorkflowExecutionLogResponse) => {
    return log.status === WorkflowExecutionStatus.COMPLETED ||
      log.status === WorkflowExecutionStatus.FAILED ||
      log.status === WorkflowExecutionStatus.SUSPENDED;
  }, []);

  useEffect(() => {
    if (workflowId) {
      setIsWorkflowNameLoading(true);
      getWorkflowV1(workflowId, accessToken)
        .then(workflow => {
          setWorkflowName(workflow.name);
          setIsWorkflowNameLoading(false);
        })
        .catch(() => {
          setIsWorkflowNameLoading(false);
        });
    }
  }, [workflowId]);  // eslint-disable-line react-hooks/exhaustive-deps

  usePolling(
    async () => {
      const log = await fetchWorkflowExecutionLog(executionId);
      return log;
    },
    {
      interval: 2000,
      enabled: true,
      continuePollingOnSuccess: true,
      isComplete: isWorkflowComplete,
      lastPollTimeRef,
      onSuccess: (log: WorkflowExecutionLogResponse) => {
        setExecutionLog(log);
        setWorkflowId(log.request.workflow_id);
        if (log.result?.steps) {
          const uiSteps = log.result.steps.map(transformStepToUIFormat);
          setSteps(uiSteps);
          setIsStepsLoading(false);
        }

        if (log.chat_history?.length && messages.length === 0) {
          const uiMessages = log.chat_history.flatMap(chat => [
            {
              role: 'user' as const,
              content: chat.question,
              timestamp: new Date(chat.timestamp),
              attachments: chat.documents?.map(docId => ({
                _id: docId,
                filename: '',
                blob_url: '',
                category: 'chat',
                origin: 'workflow',
                owner_uid: '',
                owner_oid: '',
                run_id: executionId,
                description: '',
                user_data: {},
              })) || []
            },
            {
              role: 'assistant' as const,
              content: chat.answer,
              timestamp: new Date(chat.timestamp),
              citations: chat.bbox_with_docs
            }
          ]);

          setMessages(uiMessages);
        }

        setIsLoading(false);
      },
      onError: (error: unknown) => {
        console.error('Failed to fetch workflow execution log:', error);
        toast.error('Failed to fetch workflow status');
        setIsLoading(false);
        setIsStepsLoading(false);
      }
    }
  );

  useEffect(() => {
    if (steps && steps.length > 0 && !selectedDocTypeRef.current) {
      const classifyStep = steps.find(step => step.stepType === 'classify_documents');
      const documentTypes = classifyStep?.documentTypes;

      if (documentTypes && documentTypes.length > 0) {
        setSelectedDocType(documentTypes[0]);
        selectedDocTypeRef.current = documentTypes[0];
      }
    }
  }, [steps]);

  const handleDocTypeChange = (value: string) => {
    setSelectedDocType(value);
    selectedDocTypeRef.current = value;
  };

  const filterDocumentsByType = (step: UIWorkflowStep) => {
    if (step.stepType !== 'classify_documents' || !step.documents) {
      return [];
    }
    return step.documents.filter(doc => doc.className === selectedDocType);
  };

  const getStepStatus = useCallback((stepId: string, stepIndex: number) => {
    if (!executionLog) return 'pending';
    if (!executionLog.result) return 'pending';

    if (executionLog.status === WorkflowExecutionStatus.FAILED) {
      return 'failed';
    }

    const currentStep = executionLog.result.steps[stepIndex];
    if (!currentStep || currentStep.step_type !== stepId) return 'pending';

    const activeStepIndex = executionLog.result.steps.findIndex(step =>
      !step.output || Object.keys(step.output).length === 0
    );

    if (executionLog.status === WorkflowExecutionStatus.RUNNING) {
      if (stepIndex === activeStepIndex) {
        return 'running';
      }

      if (activeStepIndex === -1 || stepIndex < activeStepIndex) {
        return 'completed';
      }

      return 'pending';
    }

    if (executionLog.status === WorkflowExecutionStatus.COMPLETED) {
      const hasValidOutput = currentStep.output &&
        Object.keys(currentStep.output).length > 0 &&
        Object.values(currentStep.output).some(value =>
          value !== null &&
          value !== undefined &&
          (typeof value === 'object' ? Object.keys(value).length > 0 : true)
        );
      return hasValidOutput ? 'completed' : 'pending';
    }

    return 'pending';
  }, [executionLog]);

  const getLoadingText = (stepTitle: string) => {
    if (stepTitle.toLowerCase().includes('classify')) return 'Classifying documents...';
    if (stepTitle.toLowerCase().includes('extract')) return 'Extracting data...';
    return 'Processing...';
  };

  const handleDocumentClick = async (doc: { documentId: string, fileType?: string }) => {
    if (doc.fileType === 'pdf' || doc.fileType === 'xlsx') {
      try {
        if (doc.fileType === 'xlsx') {
          doc.fileType = 'excel';
        }
        const url = await getPdfUrl(doc.documentId);
        setPreviewDocument({ url, fileType: doc.fileType });
      } catch (error) {
        console.error('Failed to fetch PDF URL:', error);
        toast.error('Unable to preview document. Please try again later.');
      }
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;
    if (newFiles && uploadDocument) {
      setIsUploading(true);
      try {
        const validFiles = Array.from(newFiles).filter(file => {
          const fileType = file.type.toLowerCase();
          return fileType === 'application/pdf' ||
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        });

        for (const file of validFiles) {
          try {
            const uploadRequest: UserDocumentUploadRequest = {
              filename: file.name,
              blob_url: "",
              origin: "workflow",
              category: "chat",
              owner_uid: "",
              owner_oid: "",
              run_id: executionId,
            };

            const result = await uploadDocument(file, uploadRequest);
            if (result) {
              const { user_document_id, sas_url } = result;
              const newDocument: UserDocument = {
                _id: user_document_id,
                filename: file.name,
                blob_url: sas_url,
                category: "chat",
                origin: "workflow",
                owner_uid: "",
                owner_oid: "",
                run_id: executionId,
                description: "",
                user_data: {},
              };
              setUploadedDocuments(prev => [...prev, newDocument]);
            }
          } catch (error) {
            console.error(`Failed to upload file ${file.name}:`, error);
            toast.error(`Failed to upload file ${file.name}`);
          }
        }
      } finally {
        setIsUploading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    }
  };

  const removeFile = async (fileName: string) => {
    const documentToRemove = uploadedDocuments.find(doc => doc.filename === fileName);
    if (documentToRemove && deleteDocument) {
      try {
        await deleteDocument(documentToRemove._id);
        setUploadedDocuments((prevDocs) => prevDocs.filter((doc) => doc._id !== documentToRemove._id));
      } catch (error) {
        console.error(`Failed to delete document ${fileName}:`, error);
        toast.error(`Failed to delete document ${fileName}`);
      }
    }
  };

  const handleSendMessage = async (message: string) => {
    if (!message.trim()) return;
    setIsStreaming(true);

    try {
      const userMessage: Message = {
        role: 'user',
        content: message,
        timestamp: new Date(),
        attachments: uploadedDocuments
      };
      setMessages(prev => [...prev, userMessage]);

      setUploadedDocuments([]);

      const response = await fetchWorkkflowExecutionAskAI(
        executionId,
        message,
        uploadedDocuments.map(doc => doc._id),
        filteredDocumentIds
      );

      setShouldInvalidateCache(true);

      if (response.bbox_with_docs) {
        const newCitations: CitationWithDocId[] = response.bbox_with_docs.flatMap(doc =>
          doc.bbox_values.map(bbox => ({
            citation: bbox,
            state: 'match',
            documentId: doc.document_id
          }))
        );
        setSelectedCitations(prev => [...prev, ...newCitations]);
      }

      const assistantMessage: Message = {
        role: 'assistant',
        content: response.answer,
        timestamp: new Date(),
        citations: response.bbox_with_docs
      };
      setMessages(prev => [...prev, assistantMessage]);
    } catch (error) {
      console.error('Error getting AI response:', error);
      toast.error('Failed to get AI response');
    } finally {
      setIsStreaming(false);
      setTimeout(() => {
        setShouldInvalidateCache(false);
      }, 100);
    }
  };

  const renderShimmer = () => (
    <div>
      <div className="bg-white py-8 px-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center text-md">
            <div className="h-5 w-32 bg-gray-200 rounded animate-pulse" />
            <ChevronRight className="h-4 w-4 mx-2 text-gray-300" />
            <div className="h-5 w-16 bg-gray-200 rounded animate-pulse" />
          </div>
        </div>
      </div>


      <div className="flex-1 px-6 pb-4">
        <div className="max-w-7xl mx-auto w-full">
          <div className="max-w-3xl">

            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
              <div className="p-6">
                <div className="relative">

                  <div className="absolute left-[11px] top-[24px] bottom-4 w-[2px] bg-gray-100" />


                  <div className="space-y-8">
                    {[1, 2, 3, 4].map((index) => (
                      <div key={index} className="relative">
                        <div className="flex items-start">

                          <div className="relative z-10 mr-4 rounded-full bg-white p-0.5">
                            <div className="w-5 h-5 rounded-full bg-gray-200 animate-pulse" />
                          </div>


                          <div className="flex-1 pt-0.5">
                            <div className="flex items-center justify-between mb-4">
                              <div className="h-5 w-48 bg-gray-200 rounded animate-pulse" />
                              <div className="h-5 w-5 rounded-full bg-gray-200 animate-pulse" />
                            </div>


                            <div className="space-y-3">
                              {index === 2 && (
                                <>
                                  <div className="h-9 w-[180px] bg-gray-200 rounded animate-pulse" />
                                  <div className="grid grid-cols-2 gap-4">
                                    {[1, 2].map((i) => (
                                      <div key={i} className="flex items-center p-2 rounded-md border border-gray-100">
                                        <div className="w-8 h-8 rounded-lg bg-gray-200 animate-pulse mr-3" />
                                        <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                              {(index === 3 || index === 4) && (
                                <div className="h-9 w-28 bg-gray-200 rounded animate-pulse" />
                              )}
                            </div>
                          </div>
                        </div>


                        {index < 4 && (
                          <div className="h-px bg-gray-200 my-6 ml-10" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>


            <div className="sticky bottom-5 left-0 right-0 mt-4">
              <div className="bg-white border border-gray-200 rounded-lg p-4">
                <div className="h-10 bg-gray-200 rounded animate-pulse" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <DocumentPreviewDialog
        isOpen={false}
        onClose={() => void 0}
        previewUrl=""
        fileType="pdf"
      />
    </div>
  );

  const scrollToBottom = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView();
  };

  useEffect(() => {
    if (messages.length > 0 && !isStreaming) {
      scrollToBottom(messagesEndRef);
    }
  }, [messages, isStreaming]);

  useEffect(() => {
    if (messages.length > 0) {
      const timeoutId = setTimeout(() => {
        scrollToBottom(messagesEndRef);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [isInCanvas, messages.length]);

  const toggleStep = (stepId: string, index: number) => {
    const uniqueStepId = `${stepId}-${index}`;
    setExpandedSteps(prev => ({
      ...prev,
      [uniqueStepId]: !prev[uniqueStepId]
    }));
  };

  useEffect(() => {
    if (steps.length > 0) {
      const isWorkflowDone = executionLog?.status === WorkflowExecutionStatus.COMPLETED ||
        executionLog?.status === WorkflowExecutionStatus.FAILED;

      if (isWorkflowDone) {
        const initialExpanded = steps.reduce((acc, step, index) => {
          acc[`${step.id}-${index}`] = index === steps.length - 1;
          return acc;
        }, {} as { [key: string]: boolean });
        setExpandedSteps(initialExpanded);
      } else {
        const initialExpanded = steps.reduce((acc, step, index) => {
          acc[`${step.id}-${index}`] = true;
          return acc;
        }, {} as { [key: string]: boolean });
        setExpandedSteps(initialExpanded);
      }
    }
  }, [steps, executionLog?.status]);

  const handleGuidelinesClick = () => {
    if (!isInCanvas) {
      setIsInCanvas(true);
      setRightContent(
        <GuidelinesDetail
          initialExecutionId={executionId}
          isEmbedded={true}
        />
      );
    } else {
      setRightContent(
        <GuidelinesDetail
          initialExecutionId={executionId}
          isEmbedded={true}
        />
      );
    }
  };

  const handleAuditClick = (auditLink: string) => {
    const auditId = auditLink.split('/').pop();

    if (!isInCanvas) {
      setIsInCanvas(true);
      setRightContent(
        <UwAuditDetail
          initialAuditId={auditId}
          isEmbedded={true}
        />
      );
    } else {
      setRightContent(
        <UwAuditDetail
          initialAuditId={auditId}
          isEmbedded={true}
        />
      );
    }
  };

  const handleCompareClick = (compareLink: string) => {
    const compareId = compareLink?.split('/').pop();

    if (!compareId) {
      console.error('No compare ID found in link:', compareLink);
      return;
    }

    if (!isInCanvas) {
      setIsInCanvas(true);
    }

    setRightContent(
      <CompareDetail
        key={compareId}
        initialCompareId={compareId}
        isEmbedded={true}
      />
    );
  };

  const handleExcelLogClick = (excelLogId: string | undefined) => {
    if (!excelLogId) {
      toast.error('No excel log ID provided');
      return;
    }

    if (!isInCanvas) {
      setIsInCanvas(true);
    }

    setRightContent(
      <FillExcelDetail
        key={excelLogId}
        initialExcelId={excelLogId}
        isEmbedded={true}
      />
    );
  };

  const handleCanvasClose = () => {
    setIsInCanvas(false);
    setRightContent(null);
  };

  const handleCitationClick = async (snippet: AnswerSnippet) => {
    setIsPdfSliderOpen(true);
    setIsLoadingPdf(true);

    const citations: CitationWithDocId[] = snippet.bbox_values.map(bboxValue => ({
      citation: bboxValue,
      state: 'match',
      documentId: snippet.document_id
    }));

    setSelectedCitations(citations);

    try {
      if (!pdfUrls.has(snippet.document_id)) {
        const documentDetails = await fetchUserDocument(snippet.document_id);
        const fileType = documentDetails.filename.split('.').pop()?.toLowerCase();

        const url = await getPdfUrl(snippet.document_id);
        setPdfUrls(produce(draft => {
          draft.set(snippet.document_id, url);
        }));
        setCurrentPdfUrl(url);

        setCurrentFileType(
          fileType === 'xlsx' ? 'excel' : 'pdf'
        );
      } else {
        const url = pdfUrls.get(snippet.document_id) || null;
        setCurrentPdfUrl(url);

        const documentDetails = await fetchUserDocument(snippet.document_id);
        const fileType = documentDetails.filename.split('.').pop()?.toLowerCase();
        setCurrentFileType(
          fileType === 'xlsx' ? 'excel' : 'pdf'
        );
      }
    } catch (error) {
      console.error('Failed to fetch document:', error);
      toast.error('Unable to preview document');
    } finally {
      setIsLoadingPdf(false);
    }
  };

  const handleExtractLogClick = (extractLink: string) => {
    const extractId = extractLink?.split('/').pop();

    if (!extractId) {
      console.error('No extract ID found in link:', extractLink);
      return;
    }

    if (!isInCanvas) {
      setIsInCanvas(true);
    }

    setRightContent(
      <ExtractDetail
        key={extractId}
        initialExtractId={extractId}
        isEmbedded={true}
      />
    );
  };

  const handleEmailClick = (emailBody: string) => {
    if (!isInCanvas) {
      setIsInCanvas(true);
      setRightContent(
        <EmailDetail
          emailBody={emailBody}
          isEmbedded={true}
        />
      );
    } else {
      setRightContent(
        <EmailDetail
          emailBody={emailBody}
          isEmbedded={true}
        />
      );
    }
  };

  const workflowContent = (
    <div className="h-full flex flex-col">
      <div className="py-10 px-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center text-md text-gray-500">
            {isWorkflowNameLoading ? (
              <div className="flex items-center">
                <div className="h-5 w-32 bg-gray-200 rounded animate-pulse" />
                <ChevronRight className="h-4 w-4 mx-2 text-gray-300" />
                <div className="h-5 w-16 bg-gray-200 rounded animate-pulse" />
              </div>
            ) : (
              <>
                <span
                  className="hover:text-gray-700 cursor-pointer"
                  onClick={() => navigate(`/workflows/${executionLog?.request.workflow_id}`)}
                >
                  {workflowName}
                </span>
                <ChevronRight className="h-4 w-4 mx-2" />
                <span className="font-medium text-gray-900">Steps</span>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex-1 px-6 pb-4">
        <div className="max-w-7xl mx-auto w-full">
          <div className="max-w-3xl">
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
              <div className="p-6">
                <div className="relative">
                  <div className="absolute left-[11px] top-[24px] bottom-4 w-[2px] bg-gray-100" />

                  <div className="space-y-1">
                    {isStepsLoading ? (
                      [1, 2, 3].map((index) => (
                        <div key={index} className="relative">
                          <div className="flex items-start">
                            <div className="relative z-[1] mr-4 rounded-full bg-white p-0.5">
                              <div className="w-5 h-5 rounded-full bg-gray-200 animate-pulse" />
                            </div>

                            <div className="flex-1 min-h-[32px] flex flex-col justify-center">
                              <div className="flex items-center justify-between">
                                <div className="h-5 w-48 bg-gray-200 rounded animate-pulse" />
                                <div className="h-5 w-5 rounded-full bg-gray-200 animate-pulse" />
                              </div>

                              <div className="space-y-3">
                                <div className="h-4 w-full bg-gray-200 rounded animate-pulse" />
                                <div className="h-4 w-5/6 bg-gray-200 rounded animate-pulse" />
                                <div className="h-4 w-2/3 bg-gray-200 rounded animate-pulse" />
                              </div>
                            </div>
                          </div>

                          {index < 3 && (
                            <div className="h-px bg-gray-200 my-2 ml-10" />
                          )}
                        </div>
                      ))
                    ) : (
                      steps.map((step, index) => (
                        <div
                          key={step.id}
                          className="relative"
                        >
                          <div className="flex items-start py-2">
                            <div className={cn(
                              "relative z-[1] mr-3 rounded-full bg-white p-0.5",
                              getStepStatus(step.id, index) === 'failed' ? "text-red-600" : "text-purple-600"
                            )}>
                              {getStepStatus(step.id, index) === 'completed' ? (
                                <div className="relative z-10 mr-3 rounded-full bg-white p-0.5 text-purple-600">
                                  <CircleCheck className="w-5 h-5" />
                                </div>
                              ) : getStepStatus(step.id, index) === 'running' ? (
                                <div className="relative z-10 mr-3 rounded-full bg-white p-0.5">
                                  <Loader2 className="w-5 h-5 animate-spin text-purple-600" />
                                </div>
                              ) : getStepStatus(step.id, index) === 'failed' ? (
                                <div className="relative z-10 mr-3 rounded-full bg-white p-0.5 text-red-600">
                                  <XCircle className="w-5 h-5" />
                                </div>
                              ) : (
                                <div className="w-5 h-5 rounded-full border-2 border-gray-200" />
                              )}
                            </div>

                            <div className="flex-1">
                              <div className="flex items-center justify-between mt-1">
                                <h3 className="text-sm font-medium leading-snug">
                                  {step.title}
                                </h3>
                                {hasDetailView(step.stepType) && (
                                   <button
                                   onClick={() => toggleStep(step.id, index)}
                                   className="p-1 hover:bg-gray-50 rounded-full"
                                 >
                                   <ChevronRight
                                     className={cn(
                                       "w-4 h-4 text-gray-400 transition-transform duration-200",
                                       expandedSteps[`${step.id}-${index}`] ? "transform rotate-90" : ""
                                     )}
                                   />
                                 </button>
                                )}
                              </div>

                              <div className={cn(
                                "transition-all duration-200 overflow-hidden mt-2",
                                expandedSteps[`${step.id}-${index}`] ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
                              )}>
                                {getStepStatus(step.id, index) === 'running' && (
                                  <p className="text-sm text-gray-500 mb-4">
                                    {getLoadingText(step.title)}
                                  </p>
                                )}

                                {step.stepType === 'classify_submission_type' &&
                                  step.submissionType &&
                                  getStepStatus(step.id, index) === 'completed' && (
                                    <div className="mt-2">
                                      <p className="text-sm text-gray-900">
                                        <span className="font-medium">{step.submissionType}</span>
                                      </p>
                                    </div>
                                  )}

                                {step.stepType === 'classify_documents' && step.documentTypes && (
                                  <div className="space-y-4">
                                    <Select
                                      value={selectedDocType}
                                      onValueChange={handleDocTypeChange}
                                    >
                                      <SelectTrigger className="w-[180px] focus:ring-0">
                                        <SelectValue placeholder="Select document type" />
                                      </SelectTrigger>
                                      <SelectContent className="bg-white">
                                        {step.documentTypes.map(type => (
                                          <SelectItem key={type} value={type}>{type}</SelectItem>
                                        ))}
                                      </SelectContent>
                                    </Select>

                                    <div className="grid grid-cols-2 gap-4">
                                      {filterDocumentsByType(step).map((doc) => (
                                        <div
                                          key={doc.name}
                                          className="flex items-center p-2 hover:bg-gray-50 rounded-md cursor-pointer"
                                          onClick={() => handleDocumentClick(doc)}
                                        >
                                          <div className="w-8 h-8 rounded-lg bg-purple-50 flex items-center justify-center mr-3">
                                            <FileText className="w-4 h-4 text-purple-600" />
                                          </div>
                                          <div className="flex flex-col">
                                            <span className="text-sm text-gray-900">{doc.name}</span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}

                                {step.stepType === 'extract_guidelines' && getStepStatus(step.id, index) === 'completed' && (
                                  <div className="mt-2">
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      onClick={handleGuidelinesClick}
                                    >
                                      View Guidelines
                                    </Button>
                                  </div>
                                )}

                                {step.stepType === 'check_guidelines' &&
                                  step.auditLink &&
                                  getStepStatus(step.id, index) === 'completed' && (
                                    <div className="mt-2">
                                      <Button
                                        variant="outline"
                                        size="sm"
                                        onClick={() => step.auditLink && handleAuditClick(step.auditLink)}
                                      >
                                        View Output
                                      </Button>
                                    </div>
                                  )}

                                {step.stepType === 'compare' &&
                                  step.compareLink &&
                                  getStepStatus(step.id, index) === 'completed' && (
                                    <div className="mt-2">
                                      <Button
                                        variant="outline"
                                        size="sm"
                                        onClick={() => step.compareLink && handleCompareClick(step.compareLink)}
                                      >
                                        View Output
                                      </Button>
                                    </div>
                                  )}

                                {step.stepType === 'compare_with_schema' &&
                                  step.compareLink &&
                                  getStepStatus(step.id, index) === 'completed' && (
                                    <div className="mt-2">
                                      <Button
                                        variant="outline"
                                        size="sm"
                                        onClick={() => step.compareLink && handleCompareClick(step.compareLink)}
                                      >
                                        View Output
                                      </Button>
                                    </div>
                                  )}

                                {step.stepType === 'qa' && step.answerSnippets && (
                                  <div className="space-y-3">
                                    {/* <div className="text-md text-gray-900 font-medium">
                                      {step.question}
                                    </div> */}

                                    <div className="text-sm text-gray-900">
                                      <div className="space-y-2">
                                        {step.answerSnippets.map((snippet, index) => (
                                          <div key={index} className="flex items-start">
                                            <div className="flex-1 inline-flex items-start flex-wrap gap-1">
                                              <div className="whitespace-pre-wrap break-words">
                                                {snippet.text}
                                                {snippet.bbox_values.length > 0 && (
                                                  <button
                                                    type="button"
                                                    onClick={() => handleCitationClick(snippet)}
                                                    className="inline-flex items-center justify-center rounded-full bg-gray-200 w-[18px] h-[18px] text-xs font-medium text-gray-700 hover:bg-gray-300 transition-colors cursor-pointer ml-1 align-baseline"
                                                  >
                                                    {index + 1}
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {step.stepType === 'sov_mapping' &&
                                  step.output?.link_to_excel_log &&
                                  getStepStatus(step.id, index) === 'completed' && (
                                    <div className="mt-2">
                                      <Button
                                        variant="outline"
                                        size="sm"
                                        onClick={() => {
                                          const excelLogId = step.output?.link_to_excel_log?.split('/').pop();
                                          handleExcelLogClick(excelLogId);
                                        }}
                                      >
                                        View Output
                                      </Button>
                                    </div>
                                  )}

                                {(step.stepType === 'extract_from_text' ||
                                  step.stepType === 'extract_from_document' ||
                                  step.stepType === 'extract_rows_from_multiple_documents') &&
                                  step.output?.link_to_extract_log &&
                                  getStepStatus(step.id, index) === 'completed' && (
                                    <div className="mt-2">
                                      <Button
                                        variant="outline"
                                        size="sm"
                                        onClick={() => step.output?.link_to_extract_log && handleExtractLogClick(step.output.link_to_extract_log)}
                                      >
                                        View Output
                                      </Button>
                                    </div>
                                  )}

                                {step.stepType === 'email' && getStepStatus(step.id, index) === 'completed' && (
                                  <div className="mt-2">
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      onClick={() => handleEmailClick(step.output?.email_body || '')}
                                    >
                                      View Email
                                    </Button>
                                  </div>
                                )}

                                {step.stepType === 'signature_analysis' && step.signatureAnalysis && (
                                  <div className="space-y-4">
                                    {step.signatureAnalysis.map((doc, docIndex) => (
                                      <div key={docIndex} className="space-y-2">
                                        {doc.signatures.map((sig, sigIndex) => (
                                          <div
                                            key={sigIndex}
                                            className="bg-gray-50 rounded-md p-3 space-y-1.5"
                                          >
                                            {sig.signer_name && (
                                              <div className="flex items-center text-sm">
                                                <span className="text-gray-500 w-24">Signer:</span>
                                                <span className="text-gray-900 font-medium">{sig.signer_name}</span>
                                              </div>
                                            )}
                                            {sig.signature_type && (
                                              <div className="flex items-center text-sm">
                                                <span className="text-gray-500 w-24">Type:</span>
                                                <span className="text-gray-900 capitalize">{sig.signature_type}</span>
                                              </div>
                                            )}
                                            {sig.signature_date && (
                                              <div className="flex items-center text-sm">
                                                <span className="text-gray-500 w-24">Date:</span>
                                                <span className="text-gray-900">{sig.signature_date}</span>
                                              </div>
                                            )}
                                            <div className="flex flex-wrap gap-3 pt-1">
                                              {sig.is_on_signature_line !== null && (
                                                <span className={cn(
                                                  "inline-flex items-center text-xs rounded-full px-2 py-1",
                                                  sig.is_on_signature_line
                                                    ? "bg-green-50 text-green-700"
                                                    : "bg-yellow-50 text-yellow-700"
                                                )}>
                                                  {sig.is_on_signature_line ? (
                                                    <CircleCheck className="w-3 h-3 mr-1" />
                                                  ) : (
                                                    <XCircle className="w-3 h-3 mr-1" />
                                                  )}
                                                  {sig.is_on_signature_line ? "On signature line" : "Not on signature line"}
                                                </span>
                                              )}
                                              {sig.has_date_on_date_line !== null && (
                                                <span className={cn(
                                                  "inline-flex items-center text-xs rounded-full px-2 py-1",
                                                  sig.has_date_on_date_line
                                                    ? "bg-green-50 text-green-700"
                                                    : "bg-yellow-50 text-yellow-700"
                                                )}>
                                                  {sig.has_date_on_date_line ? (
                                                    <CircleCheck className="w-3 h-3 mr-1" />
                                                  ) : (
                                                    <XCircle className="w-3 h-3 mr-1" />
                                                  )}
                                                  {sig.has_date_on_date_line ? "Date on date line" : "No date on date line"}
                                                </span>
                                              )}
                                              {sig.is_verified_digital !== null && (
                                                <span className={cn(
                                                  "inline-flex items-center text-xs rounded-full px-2 py-1",
                                                  sig.is_verified_digital
                                                    ? "bg-green-50 text-green-700"
                                                    : "bg-yellow-50 text-yellow-700"
                                                )}>
                                                  {sig.is_verified_digital ? "Verified digital" : "Not verified digital"}
                                                </span>
                                              )}
                                              {sig.is_scanned_handwritten && (
                                                <span className="inline-flex items-center text-xs bg-blue-50 text-blue-700 rounded-full px-2 py-1">
                                                  Scanned handwritten
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={cn(
              "overflow-y-auto",
              steps.length <= 2
                ? "min-h-[calc(100vh-24rem)]"
                : "min-h-[calc(100vh-38rem)]"
            )}>
              {messages.length > 0 && (
                <div className="mt-8 space-y-6">
                  {messages.map((message, index) => (
                    <div
                      key={`${message.timestamp.getTime()}-${index}`}
                      className="space-y-1"
                    >
                      <div className={cn(
                        "flex",
                        message.role === 'assistant' ? "justify-start" : "justify-end"
                      )}>
                        {message.role === 'assistant' && (
                          <div className="min-w-[24px] min-h-[24px] w-6 h-6 flex-shrink-0 flex items-center justify-center mr-2">
                            <img src={faiLogo} alt="AI" className="w-full h-full object-contain" />
                          </div>
                        )}
                        <div className={cn(
                          "rounded-xl max-w-[720px]",
                          message.role === 'assistant'
                            ? "px-2"
                            : "py-2 px-2 bg-purple-100"
                        )}>
                          <div className="break-words">
                            {message.role === 'assistant' && message.citations ? (
                              <div className="break-words">
                                {renderMessageContent({ content: message.content, role: message.role, citations: message.citations })}
                              </div>
                            ) : (
                              <div className="break-words">
                                {renderMessageContent({ content: message.content, role: message.role })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {message.role === 'user' && message.attachments && message.attachments.length > 0 && (
                        <div className="flex items-center gap-1 text-xs text-gray-700 justify-end">
                          <Paperclip className="h-3 w-3" />
                          <span>{message.attachments.length} attachment{message.attachments.length !== 1 ? 's' : ''}</span>
                        </div>
                      )}
                    </div>
                  ))}
                  {isStreaming && (
                    <div className="flex items-center space-x-2 justify-center py-2">
                      <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                      <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                      <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce"></div>
                    </div>
                  )}
                  <div ref={messagesEndRef} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="sticky bottom-6 left-0 right-0 px-6 z-20">
        <div className="max-w-7xl mx-auto">
          <div className="max-w-3xl">
            <div className="mx-2">
              <PromptInput
                onSend={handleSendMessage}
                isStreaming={isStreaming}
                uploadedDocuments={uploadedDocuments}
                onFileUpload={handleFileUpload}
                onDeleteDocument={removeFile}
                isUploading={isUploading}
                promptCategory={`workflow-${executionLog?.request.workflow_id}`}
                onUploadFromSharePoint={selectAndUploadFile}
                isSharePointLoading={isSharePointLoading}
                workflowExecutionId={executionId}
                onMentionedDocumentsChange={setFilteredDocumentIds}
                invalidateCache={shouldInvalidateCache}
              />
            </div>
          </div>
        </div>
      </div>

      <DocumentPreviewDialog
        isOpen={!!previewDocument}
        onClose={() => setPreviewDocument(null)}
        previewUrl={previewDocument?.url || ''}
        fileType={previewDocument?.fileType as 'pdf' | 'excel' | 'image'}
      />
      <PDFPreviewSlider
        isOpen={isPdfSliderOpen}
        onClose={() => {
          setIsPdfSliderOpen(false);
          setCurrentPdfUrl(null);
          setSelectedCitations([]);
          setCurrentFileType('pdf');
        }}
        pdfUrl={currentPdfUrl}
        citations={selectedCitations}
        isLoading={isLoadingPdf}
        fileType={currentFileType}
      />
    </div>
  );

  useEffect(() => {
    const handleCitationClick = async (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.classList.contains('citation-link')) {
        e.preventDefault();
        e.stopPropagation();

        const index = parseInt(target.getAttribute('data-citation-index') || '0', 10);
        const documentId = target.getAttribute('data-document-id');

        if (documentId) {
          setIsPdfSliderOpen(true);
          setIsLoadingPdf(true);

          const message = messages.find(m =>
            m.citations?.some(doc =>
              doc.document_id === documentId &&
              doc.bbox_values[index]?.content === target.getAttribute('data-citation-content')
            )
          );

          const citation = message?.citations?.find(doc =>
            doc.document_id === documentId
          )?.bbox_values[index];

          if (citation) {
            setSelectedCitations([{
              citation,
              state: 'match',
              documentId
            }]);

            try {
              if (!pdfUrls.has(documentId)) {
                const documentDetails = await fetchUserDocument(documentId);
                const fileType = documentDetails.filename.split('.').pop()?.toLowerCase();

                const url = await getPdfUrl(documentId);
                setPdfUrls(produce(draft => {
                  draft.set(documentId, url);
                }));
                setCurrentPdfUrl(url);

                setCurrentFileType(
                  fileType === 'xlsx' ? 'excel' : 'pdf'
                );
              } else {
                const url = pdfUrls.get(documentId) || null;
                setCurrentPdfUrl(url);

                const documentDetails = await fetchUserDocument(documentId);
                const fileType = documentDetails.filename.split('.').pop()?.toLowerCase();
                setCurrentFileType(
                  fileType === 'xlsx' ? 'excel' : 'pdf'
                );
              }
            } catch (error) {
              console.error('Failed to fetch document:', error);
              toast.error('Unable to preview document');
            } finally {
              setIsLoadingPdf(false);
            }
          }
        }
      }
    };

    document.addEventListener('click', handleCitationClick);
    return () => document.removeEventListener('click', handleCitationClick);
  }, [messages, getPdfUrl, fetchUserDocument, pdfUrls]);

  return isLoading ? renderShimmer() : (
    isInCanvas ? (
      <Canvas
        leftContent={workflowContent}
        rightContent={rightContent || <div className="p-10">Select an action to view details</div>}
        onClose={handleCanvasClose}
      />
    ) : (
      <div>
        {workflowContent}
      </div>
    )
  );
};

export const WorkflowV1 = withRequiredAuthInfo(({
  accessToken,
  userClass,
  isInCanvas
}: {
  accessToken: string;
  userClass: UserClass;
  isInCanvas?: boolean;
}) => {
  const { executionId } = useParams<{ executionId: string }>();

  if (!executionId) {
    return <div>No execution ID provided</div>;
  }

  return (
    <WorkflowPresentation
      executionId={executionId}
      accessToken={accessToken}
      userClass={userClass}
      isInCanvas={isInCanvas}
    />
  );
});
