import { Components } from 'react-markdown';
import { DetailedHTMLProps, HTMLAttributes, TableHTMLAttributes, ImgHTMLAttributes } from 'react';

export const markdownComponents: Components = {
  table: (props: DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>) => (
    <table className="min-w-full divide-y divide-gray-200" {...props} />
  ),
  th: (props: DetailedHTMLProps<HTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) => (
    <th className="px-3 py-2 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider" {...props} />
  ),
  td: (props: DetailedHTMLProps<HTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) => (
    <td className="px-3 py-2 text-sm font-medium text-gray-700 whitespace-normal" {...props} />
  ),
  thead: (props: DetailedHTMLProps<HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) => (
    <thead className="bg-gray-50" {...props} />
  ),
  tbody: (props: DetailedHTMLProps<HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) => (
    <tbody className="bg-white divide-y divide-gray-200" {...props} />
  ),
  tr: (props: DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) => (
    <tr className="hover:bg-gray-50" {...props} />
  ),
  h1: (props: DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) => (
    <h1 className="text-3xl font-bold mt-6 mb-4" {...props} />
  ),
  h2: (props: DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) => (
    <h2 className="text-2xl font-semibold mt-5 mb-3" {...props} />
  ),
  h3: (props: DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) => (
    <h3 className="text-xl font-medium mt-4 mb-2" {...props} />
  ),
  strong: (props: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) => (
    <span className="font-bold" {...props} />
  ),
  ul: (props: DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>) => (
    <ul className="list-disc pl-5 my-3" {...props} />
  ),
  ol: (props: DetailedHTMLProps<HTMLAttributes<HTMLOListElement>, HTMLOListElement>) => (
    <ol className="list-decimal pl-5 my-3" {...props} />
  ),
  li: (props: DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement>) => (
    <li className="my-1" {...props} />
  ),
  blockquote: (props: DetailedHTMLProps<HTMLAttributes<HTMLQuoteElement>, HTMLQuoteElement>) => (
    <blockquote className="border-l-4 border-gray-300 pl-4 py-2 my-4 italic" {...props} />
  ),
  code: (props: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & { className?: string }) => {
    const { children, className } = props;
    const match = /language-(\w+)/.exec(className || '');
    return match ? (
      <pre className="bg-gray-100 rounded p-2 overflow-x-auto my-4 whitespace-pre-wrap break-words">
        <code className={className} {...props}>
          {children}
        </code>
      </pre>
    ) : (
      <code className="bg-gray-100 rounded px-1 py-0.5 font-mono text-sm break-words" {...props}>
        {children}
      </code>
    );
  },
  pre: (props: DetailedHTMLProps<HTMLAttributes<HTMLPreElement>, HTMLPreElement>) => (
    <pre className="bg-gray-100 rounded p-2 overflow-x-auto my-4 whitespace-pre-wrap break-words" {...props} />
  ),
  img: (props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => (
    <img className="max-w-full h-auto my-4" {...props} />
  ),
};
