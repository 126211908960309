import React from "react";
import { Card, CardContent } from "../ui/card";
import { ScrollArea } from "../ui/scroll-area";
import DOMPurify from 'dompurify';

interface EmailDetailProps {
  emailBody: string;
  isEmbedded?: boolean;
}

class EmailContentErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div className="text-red-500">Error rendering email content</div>;
    }
    return this.props.children;
  }
}

const sanitizeAndRenderHTML = (content: string) => {
  if (!content) return null;

  try {
    const decodedContent = content
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'");

    const sanitizedContent = DOMPurify.sanitize(decodedContent, {
      ALLOWED_TAGS: [
        'p', 'br', 'strong', 'em', 'ul', 'ol', 'li', 'a', 'span', 'div', 'pre',
        'table', 'tbody', 'tr', 'td', 'th', 'hr', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'b', 'i', 'u', 'style', 'font', 'img'
      ],
      ALLOWED_ATTR: [
        'href', 'target', 'rel', 'class', 'style', 'src', 'alt', 'title',
        'width', 'height', 'border', 'cellspacing', 'cellpadding', 'valign',
        'align', 'bgcolor', 'color', 'size', 'face'
      ],
      ALLOW_DATA_ATTR: true,
      ADD_TAGS: ['meta', 'head'],
      ADD_ATTR: ['charset', 'content'],
      WHOLE_DOCUMENT: true
    });

    if (!sanitizedContent.trim()) return null;

    const processedContent = sanitizedContent
      .replace(/<img[^>]+src="cid:[^>]+>/g, '')
      .replace(/<img[^>]+src="[^"]*"[^>]*>/g, (match) => {
        return match.includes('http://') || match.includes('https://') ? match : '';
      });

    return (
      <div
        className="email-content"
        style={{
          fontFamily: 'Calibri, Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '1.4',
          color: '#000',
          whiteSpace: processedContent.includes('<pre') ? 'pre-wrap' : 'normal',
        }}
        dangerouslySetInnerHTML={{
          __html: processedContent
        }}
      />
    );
  } catch (error) {
    console.error('HTML sanitization failed:', error);
    return <div className="text-red-500">Error rendering content</div>;
  }
};

const EmailDetailComponent = ({
  emailBody,
  isEmbedded = false
}: EmailDetailProps) => {
  return (
    <div className={`flex-1 overflow-auto ${isEmbedded ? 'h-full' : 'min-h-screen'} bg-white`}>
      <div className="mx-auto">
        <div className="bg-white h-full overflow-y-auto">
          <div className="p-4 mt-3">
            <div className="flex items-center text-md text-gray-500 mb-2">
              <span className="font-medium text-gray-900">Email Content</span>
            </div>

            <Card className="shadow-sm">
              <CardContent className="p-4">
                <ScrollArea
                  className="h-[calc(100vh-150px)]"
                  type="auto"
                >
                  <div className="email-wrapper">
                    <style>{`
                      .email-wrapper {
                        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
                      }
                      .email-wrapper p {
                        margin: 0.5em 0;
                      }
                      .email-wrapper table {
                        margin: 0.5em 0;
                      }
                      .email-wrapper td {
                        padding: 2px 4px;
                      }
                      .email-wrapper br + br {
                        display: none;
                      }
                      .email-wrapper div:empty {
                        display: none;
                      }
                      .email-wrapper > div > p:first-child:empty {
                        display: none;
                      }
                      .email-wrapper hr {
                        margin: 1em 0;
                        border-color: #e5e7eb;
                      }
                    `}</style>
                    <EmailContentErrorBoundary>
                      {sanitizeAndRenderHTML(emailBody)}
                    </EmailContentErrorBoundary>
                  </div>
                </ScrollArea>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EmailDetail = EmailDetailComponent;
