import axios from 'axios';
import { SSE } from 'sse.js';
import { SchemaDefinition, FileTemplate } from '../config/nodeTypeConfigs';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export interface NodeInput {
  [key: string]: string | number | boolean | null | SchemaDefinition | FileTemplate | Array<string | number | boolean | null | SchemaDefinition | FileTemplate> | Array<{ input: string, data: string }>;
}

export interface NodeData {
  type: string;
  _id?: string;
  input: NodeInput;
  updated_at?: string;
}

export interface WorkflowData {
  workflow_id?: string;
  name: string;
  description: string;
  nodes: NodeData[];
  edges: Array<{
    source: string;
    target: string;
    sourceHandle?: string;
    targetHandle?: string;
  }>;
}

export interface CallData {
  user_phone_number: string;
  agent_phone_number: string;
  user_prompt: string;
  template_used: string
}

export interface CallDetail {
  call_status: string;
  transcript: string;
  start_timestamp: number;
  end_timestamp: number;
}

export interface CallLogResponse {
  _id: string;
  callee_name?: string;
  call_summary?: string;
  call_outcome?: string;
  call_status?: string;
  request: {
    user_phone_number: string;
    agent_phone_number: string;
    user_prompt: string;
    template_used: string | null;
  };
  details?: CallDetail;
  owner_uid: string;
  owner_oid: string;
}

export interface AssistantRequest {
  user_documents: string[];
  user_prompt: string;
  enable_web_search: boolean;
}

export interface CallInitiationResponse {
  call_id: string;
}

export interface UserDocumentSAS {
  user_document_id: string;
  sas_url: string;
}

export interface UserDocumentUploadRequest {
  filename: string;
  blob_url: string;
  origin: string;
  category: string;
  owner_uid: string;
  owner_oid: string;
  run_id: string;
}

export interface CreateUserDocumentRequest {
  filename: string;
  blob_url: string;
  origin: string;
  category: string;
  owner_uid: string;
  owner_oid: string;
  run_id: string;
}

export interface UserDocument {
  _id: string;
  filename: string;
  blob_url: string;
  description: string;
  user_data: Record<string, unknown>;
  category: string;
  origin: string;
  run_id: string;
  owner_uid: string;
  owner_oid: string;
}

export interface CompareDocumentsRequest {
  documents: string[];
  user_schema: string;
}

export interface CompareDocumentsResponse {
  compare_log_id: string;
}

export interface Citation {
  type: string;
  bbox: {
    left: number;
    top: number;
    width: number;
    height: number;
    page: number;
  };
  content: string;
  image_url: string | null;
}

export interface CitationWithState {
  citation: Citation;
  state: 'match' | 'mismatch';
}

export interface CompareLogResponse {
  _id: string;
  created_at: string;
  status: string;
  request: {
    documents: string[];
    user_schema: string;
    additional_context: string | null;
  };
  result: {
    header: string[];
    rows: Array<{
      title: string;
      data: string[];
      citations: Citation[][] | Record<string, Citation[]>[] | null;
      num_matches: number;
      num_mismatches: number;
    }>;
    summary?: string;
  };
  owner_name: string;
}

export interface GenerateCompareExcelResponse {
  user_document_id: string;
}

export interface DownloadDocumentResponse {
  user_document_id: string;
  sas_url: string;
}

export interface FillExcelSOVRequest {
  documents: string[];
}

export interface FillExcelLossRunRequest {
  documents: string[];
}

export interface FillExcelResponse {
  excel_mapping_id: string;
}

export interface FillExcelLogResponse {
  _id: string;
  created_at: string;
  template_used: string;
  user_document_ids: string[];
  additional_context: string | null;
  status: string;
  owner_name: string;
  result: {
    header: string[];
    rows: {
      data: string[];
    }[];
  };
}

export interface GenerateFillExcelResponse {
  user_document_id: string;
}

export interface UserDocumentDetails {
  _id: string;
  filename: string;
  blob_url: string;
  description: string;
  user_data: Record<string, unknown>;
  category: string;
  origin: string;
  run_id: string;
  owner_uid: string;
  owner_oid: string;
}

export interface SubmissionResponse {
  submissions: SubmissionData[];
  next_cursor: string | null;
}

export interface SubmissionData {
  submission_id: string;
  data: {
    sender_name?: string;
    date_processed?: string;
    effective_date?: string;
    submission_name?: string;
    total_insured_value?: string;
    is_controlled_business_str?: string;
    is_controlled_business?: boolean;
    price_target?: string;
    ingestion_spreadsheet_path?: string;
    loss_run_spreadsheet_path?: string;
  }
}

export interface SubmissionEmailData {
  previous_email_body_html?: string;
  previous_email_attachment_blobs?: {
    name: string;
    url: string;
  }[];
  body_without_previous_email_html?: string;
  output_email_attachment_blobs?: {
    name: string;
    url: string;
  }[];
  title?: string;
  sender_name?: string;
  subject?: string;
}

export interface GenerateSchemaRequest {
  prompt: string;
  category: string;
}

export interface GenerateSchemaResponse {
  _id: string | null;
  name: string;
  json_schema: string;
  category: string;
  owner_uid: string;
  owner_oid: string;
}

export interface ExtractMultiRequest {
  documents: string[];
  user_schema: string;
  options?: {
    outputAsGrid?: boolean;
  };
  additional_context?: string | null;
}

export interface ExtractRequest {
  document: string;
  user_schema: string;
  additional_context?: string | null;
}


export interface ExtractResponse {
  extract_log_id: string;
}

export type ExtractedValue = string | number | boolean | null
  | Array<string | number | boolean | null>
  | Array<{ [key: string]: ExtractedValue }>
  | { [key: string]: ExtractedValue };

export interface ExtractMultiLogResponse {
  _id: string;
  created_at: string;
  status: string;
  request: {
    documents: string[];
    user_schema: string;
    options: Record<string, unknown> | null;
  };
  result: {
    results: Array<{
      data: Record<string, ExtractedValue>;
      citations: Record<string, Citation[]>;
    }>;
  };
  owner_uid: string;
  owner_oid: string;
  owner_name: string;
}

export interface ExtractLogResponse {
  _id: string;
  created_at: string;
  status: string;
  request: {
    document: string;
    user_schema: string;
    additional_context: string | null;
  };
  result: {
    data: { [key: string]: ExtractedValue };
    citations: {
      [key: string]: Citation[];
    };
  };
  owner_uid: string;
  owner_oid: string;
  owner_name: string;
}

export interface CreateUserSchemaRequest {
  name: string;
  json_schema: string;
  category: string;
  owner_uid: string;
  owner_oid: string;
  options?: {
    outputAsGrid?: boolean;
  };
}

export interface UserSchemaResponse {
  _id: string;
  name: string;
  json_schema: string;
  category: string;
}

export interface GetUserSchemasResponse {
  _id: string;
  name: string;
  json_schema: string;
  category: string;
  options?: { outputAsGrid?: boolean };
  owner_uid: string;
  owner_oid: string;
}
export interface CreateUserPromptRequest {
  name: string;
  content: string;
  category: string;
  owner_uid: string;
  owner_oid: string;
}

export interface UserPromptResponse {
  _id: string;
  name: string;
  content: string;
  category: string;
}

export interface GetUserPromptsResponse {
  _id: string;
  name: string;
  content: string;
  category: string;
  owner_uid: string;
  owner_oid: string;
}

export interface AuditRequest {
  documents: string[];
  audit_type: string;
  additional_context?: string | null;
}

export interface AuditResponse {
  audit_log_id: string;
}

export interface AuditRow {
  title: string;
  data: string[];
  citations?: Citation[];
  citation_document_ids?: string[];
  status: string;
}

export interface AuditResult {
  summary?: string;
  header: string[];
  documents: string[];
  document_filenames?: string[];
  rows: AuditRow[];
}

export interface AuditLogResponse {
  _id: string;
  created_at: string;
  status: string;
  request: {
    documents: string[];
    audit_type: string;
    additional_context: string | null;
  };
  result?: AuditResult;
  generated_excel?: string;
  owner_uid: string;
  owner_oid: string;
}

export interface GenerateAuditExcelResponse {
  user_document_id: string;
}

export interface GenerateExtractExcelResponse {
  user_document_id: string;
}

export interface CreateOAuthConnectionResponse {
  connection_id: string;
}

export interface RequestOAuthTokensResponse {
  success: boolean;
}

export interface OAuthConnection {
  id: string;
  provider: string;
  created_at: string;
}

export interface UpdateUserSchemaRequest extends CreateUserSchemaRequest {
  options?: {
    outputAsGrid?: boolean;
  } | undefined;
}

export const createOrUpdateNode = async (nodeData: NodeData, accessToken: string | null): Promise<NodeData> => {
  const url = `${API_BASE_URL}/nodes/`;
  const response = await axios.post<NodeData>(url, nodeData, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const createWorkflow = async (workflowData: WorkflowData, accessToken: string | null): Promise<WorkflowData> => {
  const url = `${API_BASE_URL}/workflows/`;
  const response = await axios.post<WorkflowData>(url, workflowData, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const initiateCall = async (callData: CallData, accessToken: string | null): Promise<CallInitiationResponse> => {
  const url = `${API_BASE_URL}/voice/calls`;
  const response = await axios.post<CallInitiationResponse>(url, callData, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getCallDetails = async (callLogId: string, accessToken: string | null): Promise<CallDetail> => {
  const url = `${API_BASE_URL}/voice/calls/${callLogId}`;
  const response = await axios.get<CallDetail>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getCallLogById = async (callLogId: string, accessToken: string | null): Promise<CallLogResponse> => {
  const url = `${API_BASE_URL}/voice/call_logs/${callLogId}`;
  const response = await axios.get<CallLogResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getCallLogs = async (accessToken: string | null): Promise<CallLogResponse[]> => {
  const url = `${API_BASE_URL}/voice/call_logs`;
  const response = await axios.get<CallLogResponse[]>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const streamAssistantResponse = async (request: AssistantRequest, accessToken: string | null): Promise<ReadableStream> => {
  const url = `${API_BASE_URL}/assistant`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.body as ReadableStream;
};

export const streamAssistantResponseSSE = (
  request: AssistantRequest,
  accessToken: string | null,
  onDelta: (data: string) => void,
  onFinal: (data: string) => void,
  onError: (error: string) => void,
  signal: AbortSignal
): SSE => {
  const url = `${API_BASE_URL}/assistant`;

  try {
    const source = new SSE(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      payload: JSON.stringify(request),
      method: 'POST',
    });

    source.addEventListener('delta', (event: MessageEvent) => {
      onDelta(event.data);
    });

    source.addEventListener('final', (event: MessageEvent) => {
      onFinal(event.data);
      source.close();
    });

    source.addEventListener('error', (_event: Event) => {
      onError('An error occurred while streaming the response.');
      source.close();
    });

    signal.addEventListener('abort', () => {
      source.close();
      onError('Streaming was cancelled.');
    });

    source.stream();

    return source;
  } catch (error) {
    onError('Failed to create SSE connection');
    throw error;
  }
};

export const getUserDocumentSAS = async (uploadRequest: UserDocumentUploadRequest, accessToken: string | null): Promise<UserDocumentSAS> => {
  const url = `${API_BASE_URL}/user-documents-upload`;
  const response = await axios.post<UserDocumentSAS>(url, uploadRequest, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const createUserDocument = async (request: CreateUserDocumentRequest, accessToken: string | null): Promise<UserDocument> => {
  const url = `${API_BASE_URL}/user-documents`;
  const response = await axios.post<UserDocument>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const UploadToAzureBlobStorage = async (sasUrl: string, file: File): Promise<void> => {
  await axios.put(sasUrl, file, {
    headers: {
      'x-ms-date': new Date().toUTCString(),
      'x-ms-version': '2025-01-05',
      'x-ms-blob-type': 'BlockBlob',
      'Content-Type': file.type,
    },
  });
};

export const compareDocuments = async (request: CompareDocumentsRequest, accessToken: string | null): Promise<CompareDocumentsResponse> => {
  const url = `${API_BASE_URL}/compare`;
  const response = await axios.post<CompareDocumentsResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getCompareLogs = async (compareLogId: string, accessToken: string | null): Promise<CompareLogResponse> => {
  const url = `${API_BASE_URL}/compare/logs/${compareLogId}`;
  const response = await axios.get<CompareLogResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getCompareLogsHistory = async (accessToken: string | null): Promise<CompareLogResponse[]> => {
  const url = `${API_BASE_URL}/compare/logs`;
  const response = await axios.get<CompareLogResponse[]>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const deleteUserDocument = async (documentId: string, accessToken: string | null): Promise<void> => {
  const url = `${API_BASE_URL}/user-documents/${documentId}`;
  await axios.delete(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
};

export const generateExcel = async (compareLogId: string, accessToken: string | null): Promise<GenerateCompareExcelResponse> => {
  const url = `${API_BASE_URL}/compare/generate-excel?compare_log_id=${compareLogId}`;
  const response = await axios.post<GenerateCompareExcelResponse>(url, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getDocumentDownloadUrl = async (userDocumentId: string, accessToken: string | null): Promise<DownloadDocumentResponse> => {
  const url = `${API_BASE_URL}/user-documents-download`;
  const response = await axios.post<DownloadDocumentResponse>(url, { user_document_id: userDocumentId }, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const fillExcelSOV = async (request: FillExcelSOVRequest, accessToken: string | null): Promise<FillExcelResponse> => {
  const url = `${API_BASE_URL}/fill-excel-sov`;
  const response = await axios.post<FillExcelResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const fillExcelLossRun = async (request: FillExcelLossRunRequest, accessToken: string | null): Promise<FillExcelResponse> => {
  const url = `${API_BASE_URL}/fill-excel-loss-run`;
  const response = await axios.post<FillExcelResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getFillExcelLog = async (fillExcelLogId: string, accessToken: string | null): Promise<FillExcelLogResponse> => {
  const url = `${API_BASE_URL}/fill-excel/logs/${fillExcelLogId}`;
  const response = await axios.get<FillExcelLogResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getFillExcelLogsHistory = async (accessToken: string | null): Promise<FillExcelLogResponse[]> => {
  const url = `${API_BASE_URL}/fill-excel/logs`;
  const response = await axios.get<FillExcelLogResponse[]>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const generateFillExcel = async (excelMappingId: string, accessToken: string | null): Promise<GenerateFillExcelResponse> => {
  const url = `${API_BASE_URL}/fill-excel/generate-excel?excel_mapping_id=${excelMappingId}`;
  const response = await axios.post<GenerateFillExcelResponse>(url, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getUserDocument = async (documentId: string, accessToken: string | null): Promise<UserDocumentDetails> => {
  const url = `${API_BASE_URL}/user-documents/${documentId}`;
  const response = await axios.get<UserDocumentDetails>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

// TODO: Add pagination support
export const getSubmissions = async (accessToken: string | null): Promise<SubmissionResponse> => {
  const url = `${API_BASE_URL}/submissions`;
  const response = await axios.get<SubmissionResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getSubmissionEmailById = async (submissionId: string, accessToken: string | null): Promise<SubmissionEmailData> => {
  const url = `${API_BASE_URL}/submissions/${submissionId}/emails`;
  const response = await axios.get<SubmissionEmailData>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const createUserSchema = async (request: CreateUserSchemaRequest, accessToken: string | null): Promise<UserSchemaResponse> => {
  const url = `${API_BASE_URL}/user-schemas`;
  const response = await axios.post<UserSchemaResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const updateUserSchema = async (
  schemaId: string,
  request: UpdateUserSchemaRequest,
  accessToken: string | null
): Promise<UserSchemaResponse> => {
  const url = `${API_BASE_URL}/user-schemas/${schemaId}`;
  const response = await axios.put<UserSchemaResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const deleteUserSchema = async (schemaId: string, accessToken: string | null): Promise<void> => {
  const url = `${API_BASE_URL}/user-schemas/${schemaId}`;
  await axios.delete(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
};

export const getUserSchemaById = async (schemaId: string, accessToken: string | null): Promise<UserSchemaResponse> => {
  const url = `${API_BASE_URL}/user-schemas/${schemaId}`;
  const response = await axios.get<UserSchemaResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getUserSchemas = async (category: string, accessToken: string | null): Promise<GetUserSchemasResponse[]> => {
  const url = `${API_BASE_URL}/user-schemas?category=${category}`;
  const response = await axios.get<GetUserSchemasResponse[]>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const generateSchema = async (request: GenerateSchemaRequest, accessToken: string | null): Promise<GenerateSchemaResponse> => {
  const url = `${API_BASE_URL}/user-schemas/generate`;
  const response = await axios.post<GenerateSchemaResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const createUserPrompt = async (request: CreateUserPromptRequest, accessToken: string | null): Promise<UserPromptResponse> => {
  const url = `${API_BASE_URL}/user-prompts`;
  const response = await axios.post<UserPromptResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const updateUserPrompt = async (promptId: string, request: CreateUserPromptRequest, accessToken: string | null): Promise<UserPromptResponse> => {
  const url = `${API_BASE_URL}/user-prompts/${promptId}`;
  const response = await axios.put<UserPromptResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const deleteUserPrompt = async (promptId: string, accessToken: string | null): Promise<void> => {
  const url = `${API_BASE_URL}/user-prompts/${promptId}`;
  await axios.delete(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
};

export const getUserPromptById = async (promptId: string, accessToken: string | null): Promise<UserPromptResponse> => {
  const url = `${API_BASE_URL}/user-prompts/${promptId}`;
  const response = await axios.get<UserPromptResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getUserPrompts = async (category: string, accessToken: string | null): Promise<GetUserPromptsResponse[]> => {
  const url = `${API_BASE_URL}/user-prompts?category=${category}`;
  const response = await axios.get<GetUserPromptsResponse[]>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const startMultiExtraction = async (request: ExtractMultiRequest, accessToken: string | null): Promise<ExtractResponse> => {
  const url = `${API_BASE_URL}/extract-multi`;
  const response = await axios.post<ExtractResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const startExtraction = async (request: ExtractRequest, accessToken: string | null): Promise<ExtractResponse> => {
  const url = `${API_BASE_URL}/extract`;
  const response = await axios.post<ExtractResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getMultiExtractionLog = async (extractLogId: string, accessToken: string | null): Promise<ExtractMultiLogResponse> => {
  const url = `${API_BASE_URL}/extract-multi/logs/${extractLogId}`;
  const response = await axios.get<ExtractMultiLogResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getExtractionLog = async (extractLogId: string, accessToken: string | null): Promise<ExtractLogResponse> => {
  const url = `${API_BASE_URL}/extract/logs/${extractLogId}`;
  const response = await axios.get<ExtractLogResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};
export const runAudit = async (request: AuditRequest, accessToken: string | null): Promise<AuditResponse> => {
  const url = `${API_BASE_URL}/audit`;
  const response = await axios.post<AuditResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getAuditLogsHistory = async (accessToken: string | null): Promise<AuditLogResponse[]> => {
  const url = `${API_BASE_URL}/audit/logs`;
  const response = await axios.get<AuditLogResponse[]>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getAuditLog = async (auditLogId: string, accessToken: string | null): Promise<AuditLogResponse> => {
  const url = `${API_BASE_URL}/audit/logs/${auditLogId}`;
  const response = await axios.get<AuditLogResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const generateAuditExcel = async (auditLogId: string, accessToken: string | null): Promise<GenerateAuditExcelResponse> => {
  const url = `${API_BASE_URL}/audit/generate-excel?audit_log_id=${auditLogId}`;
  const response = await axios.post<GenerateAuditExcelResponse>(url, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const generateMultiExtractExcel = async (extractLogId: string, accessToken: string | null): Promise<GenerateExtractExcelResponse> => {
  const url = `${API_BASE_URL}/extract-multi/generate-excel?extract_log_id=${extractLogId}`;
  const response = await axios.post<GenerateExtractExcelResponse>(url, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getExtractLog = async (extractLogId: string, accessToken: string | null): Promise<GenerateExtractExcelResponse> => {
  const url = `${API_BASE_URL}/extract/generate-excel?extract_log_id=${extractLogId}`;
  const response = await axios.post<GenerateExtractExcelResponse>(url, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getMultiExtractLogsHistory = async (accessToken: string | null): Promise<ExtractMultiLogResponse[]> => {
  const url = `${API_BASE_URL}/extract-multi/logs`;
  const response = await axios.get<ExtractMultiLogResponse[]>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getExtractLogsHistory = async (accessToken: string | null): Promise<ExtractLogResponse[]> => {
  const url = `${API_BASE_URL}/extract/logs`;
  const response = await axios.get<ExtractLogResponse[]>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const createOAuthConnection = async (provider: string, redirectUri: string, accessToken: string | null): Promise<CreateOAuthConnectionResponse> => {
  const url = `${API_BASE_URL}/oauth/create?provider=${provider}&redirect_uri=${redirectUri}`;
  const response = await axios.post<CreateOAuthConnectionResponse>(url, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const requestOAuthTokens = async (code: string, connectionId: string, accessToken: string | null): Promise<RequestOAuthTokensResponse> => {
  const url = `${API_BASE_URL}/oauth/${connectionId}/request_token?auth_code=${code}`;
  const response = await axios.post<RequestOAuthTokensResponse>(url, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getOAuthConnections = async (accessToken: string | null): Promise<OAuthConnection[]> => {
  const url = `${API_BASE_URL}/oauth/connections`;
  const response = await axios.get<OAuthConnection[]>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const disconnectOAuthConnection = async (connectionId: string, accessToken: string | null): Promise<void> => {
  const url = `${API_BASE_URL}/oauth/disconnect?connection_id=${connectionId}`;
  await axios.post(url, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
};

export const deleteLogByCategory = async (category: string, logId: string, accessToken: string | null): Promise<void> => {
  const url = category === 'voice/call_logs'
    ? `${API_BASE_URL}/voice/call_logs/${logId}`
    : `${API_BASE_URL}/${category}/logs/${logId}`;

  await axios.delete(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
};

export enum WorkflowExecutionStatus {
  NOT_STARTED = "not_started",
  RUNNING = "running",
  SUSPENDED = "suspended",
  COMPLETED = "completed",
  FAILED = "failed"
}

export interface ChatMessage {
  timestamp: string;
  question: string;
  answer: string;
  documents: string[];
  bbox_with_docs?: {
    document_id: string;
    bbox_values: Citation[];
  }[];
}

export interface WorkflowExecutionLogResponse {
  _id: string;
  created_at: string;
  status: WorkflowExecutionStatus;
  request: {
    workflow_id: string;
    instruction: string;
    documents: string[];
  };
  result?: {
    steps: WorkflowStep[];
    state: Record<string, unknown>;
  };
  chat_history?: ChatMessage[];
  owner_uid: string;
  owner_oid: string;
  owner_name: string;
  column_data: Record<string, unknown>;
}

export interface WorkflowExecutionLogsResponse {
  workflow_executions: WorkflowExecutionLogResponse[];
  next_cursor: string | null;
}
export interface WorkflowColumnV1 {
  name: string;
  label: string;
  data_type: 'string' | 'money' | 'number' | 'date' | 'datetime';
}

export interface WorkflowV1 {
  workflow_id: string;
  name: string;
  options?: Record<string, unknown>;
  columns?: WorkflowColumnV1[];
}

export interface WorkflowV1ListResponse {
  workflows: WorkflowV1[];
  next_cursor: string | null;
}

export const createWorkflowV1 = async (workflowData: Partial<WorkflowV1>, accessToken: string | null): Promise<WorkflowV1> => {
  const url = `${API_BASE_URL}/workflows`;
  const response = await axios.post<WorkflowV1>(url, workflowData, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export const getWorkflowV1 = async (workflowId: string, accessToken: string | null): Promise<WorkflowV1> => {
  const url = `${API_BASE_URL}/workflows/${workflowId}`;
  const response = await axios.get<WorkflowV1>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

// TODO: Support pagination
export const getWorkflowsV1 = async (accessToken: string | null): Promise<WorkflowV1[]> => {
  const url = `${API_BASE_URL}/workflows?limit=0`;
  const response = await axios.get<WorkflowV1ListResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data.workflows;
};

export interface WorkflowExecutionRequest {
  workflow_id: string;
  instruction: string;
  documents: string[];
}

export interface WorkflowExecutionResponse {
  workflow_execution_id: string;
}

export const executeWorkflowV1 = async (
  request: WorkflowExecutionRequest,
  accessToken: string | null
): Promise<WorkflowExecutionResponse> => {
  const url = `${API_BASE_URL}/workflow-execution`;
  const response = await axios.post<WorkflowExecutionResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export interface WorkflowStepInput {
  classes?: Array<{
    name: string;
    description: string;
  }>;
  documents?: Array<{
    document: string;
    filename: string;
    category: string;
  }>;
  guideline?: {
    user_document_id: string;
    filename: string;
    category: string;
  };
  submissions?: Array<{
    user_document_id: string;
    filename: string;
    category: string;
  }>;
  submission_type?: string;
  guidelines?: string[];
  question?: string;
}

export interface WorkflowStepOutput {
  result?:
  | string
  | {
    [className: string]: Array<{
      document: string;
      filename: string;
      category: string;
    }>;
  }
  | Array<{
    document_name: string;
    signatures: Array<{
      signature_type?: string;
      is_on_signature_line?: boolean;
      has_date_on_date_line?: boolean;
      signer_name?: string;
      signature_date?: string;
      is_verified_digital?: boolean | null;
      is_scanned_handwritten?: boolean;
    }>;
  }>;
  link_to_audit_details?: string;
  link_to_compare_details?: string;
  link_to_excel_log?: string;
  link_to_extract_log?: string;
  email_body?: string;
  guidelines?: string[];
  titles?: string[];
  items?: Array<{
    question: string;
    title: string;
    acceptable_answer: string;
    full_bboxes: Array<{
      bbox: {
        left: number;
        top: number;
        width: number;
        height: number;
        page: number;
      };
      type: string;
      content: string;
      image_url: string | null;
    }>;
  }>;
  answer_snippets_with_bbox_values?: Array<{
    text: string;
    document_id: string;
    bbox_values: Array<{
      bbox: {
        left: number;
        top: number;
        width: number;
        height: number;
        page: number;
      };
      type: string;
      content: string | null;
      image_url: string | null;
    }>;
  }>;
}

export interface WorkflowStep {
  step_type: string;
  title: string;
  input: WorkflowStepInput;
  output: WorkflowStepOutput;
}

export const getWorkflowExecutionLog = async (
  executionId: string,
  accessToken: string | null
): Promise<WorkflowExecutionLogResponse> => {
  const url = `${API_BASE_URL}/workflow-execution/logs/${executionId}`;
  const response = await axios.get<WorkflowExecutionLogResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

// TODO: Support pagination
export const getWorkflowExecutionLogs = async (
  workflowId: string,
  accessToken: string | null
): Promise<WorkflowExecutionLogResponse[]> => {
  const url = `${API_BASE_URL}/workflow-execution/logs?workflow_id=${workflowId}&limit=0`;
  const response = await axios.get<WorkflowExecutionLogsResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data.workflow_executions;
};

export interface WorkflowExecutionAskAIRequest {
  question: string;
  documents_uploaded?: string[];
  documents_filtered?: string[];
}

export interface WorkflowExecutionAskAIResponse {
  answer: string;
  bbox_with_docs?: {
    document_id: string;
    bbox_values: Citation[];
  }[];
}

export const postWorkkflowExecutionAskAI = async (executionId: string, request: WorkflowExecutionAskAIRequest, accessToken: string | null): Promise<WorkflowExecutionAskAIResponse> => {
  const url = `${API_BASE_URL}/workflow-execution/logs/${executionId}/ask-ai`;
  const response = await axios.post<WorkflowExecutionAskAIResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export interface WorkflowExecutionDocumentsResponse {
  documents: {
    user_document_id: string;
    filename: string;
  }[];
}

export const getWorkflowExecutionDocuments = async (workflowExecutionId: string, accessToken: string | null): Promise<WorkflowExecutionDocumentsResponse> => {
  const url = `${API_BASE_URL}/workflow-execution/logs/${workflowExecutionId}/documents`;
  const response = await axios.get<WorkflowExecutionDocumentsResponse>(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};

export interface WorkflowAskAIRequest {
  question: string;
  documents_uploaded?: string[];
  documents_filtered?: string[];
}

export interface WorkflowAskAIResponse {
  answer: string;
  bbox_with_docs?: {
    document_id: string;
    bbox_values: Citation[];
  }[] | null;
  [key: string]: unknown;
}

export const postWorkflowAskAI = async (workflowId: string, request: WorkflowAskAIRequest, accessToken: string | null): Promise<WorkflowAskAIResponse> => {
  const url = `${API_BASE_URL}/workflow/${workflowId}/ask-ai`;
  const response = await axios.post<WorkflowAskAIResponse>(url, request, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
  return response.data;
};
