import { useState, useRef, useMemo } from "react"
import { Button } from "../ui/button"
import { Sparkle, Search, ChevronUp, ChevronDown, ChevronLeft, ChevronRight, MoreHorizontal, Plus, Trash2, Settings2 } from "lucide-react"
import { Input } from "../ui/input"
import { useNodeApi } from '../../hooks/useNodeApi';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table"
import { Checkbox } from "../ui/checkbox"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import av1 from '../../assets/avatars/av1.svg';
import { useNavigate } from 'react-router-dom'
import { UserDocument, FillExcelResponse, FillExcelLogResponse, deleteLogByCategory } from '../../services/api';
import toast from 'react-hot-toast';
import { UserClass, withRequiredAuthInfo } from "@propelauth/react";
import { usePolling } from '../../lib/usePolling';
import { POLLING_CONFIG } from '../../config/pollingConfig';
import { UploadDialog } from "../UploadDialog"
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuCheckboxItem } from "../ui/dropdown-menu"

interface SortedFillExcelData {
  id: string;
  personWhoStartedIt: string;
  personImage: string;
  senderName: string;
  numberOfDocuments: number;
  fillType: string;
  date: string;
  time: string;
}

type SortConfig = {
  key: keyof SortedFillExcelData;
  direction: 'asc' | 'desc' | null;
};

type FillType = 'sov' | 'lossRun' | 'custom';

interface Template {
  value: FillType;
  name: string;
  isCustom: boolean;
}

const columnFilterOptions = [
  { id: "senderName", label: "Sender Name" },
  { id: "fillType", label: "Template" },
  { id: "numberOfDocuments", label: "Document Count" },
  { id: "date", label: "Date" },
  { id: "time", label: "Time" },
];

const fileTypeConfigs = {
  sov: {
    mimeTypes: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ] as string[],
    errorMessage: 'Please upload XLSX files only.'
  },
  lossRun: {
    mimeTypes: [
      'application/pdf',
    ] as string[],
    errorMessage: 'Please upload PDF files only.'
  }
};

const schemaConfigs = {
  sov: {
    name: "SOV Schema",
    value: "sov",
    schema: JSON.stringify({
      properties: {
        "Location #": { title: "Location #", type: "string" },
        "Building #": { title: "Building #", type: "string" },
        "Location Name": { title: "Location Name", type: "string" },
        "Legal Entity": { title: "Legal Entity", type: "string" },
        "Street Address 1": { title: "Street Address 1", type: "string" },
        "Address Line 2": { title: "Address Line 2", type: "string" },
        "City": { title: "City", type: "string" },
        "State": { title: "State", type: "string" },
        "Zip Code": { title: "Zip Code", type: "string" },
        "County": { title: "County", type: "string" },
        // ... add all other SOV fields ...
      }
    })
  },
  lossRun: {
    name: "Loss Run Schema",
    value: "lossRun",
    schema: JSON.stringify({
      properties: {
        "Loss Run / Claim Type": { title: "Loss Run / Claim Type", type: "string" },
        "Carrier or MGA": { title: "Carrier or MGA", type: "string" },
        "Loss Run Report Date": { title: "Loss Run Report Date", type: "string" },
        "Insured Name": { title: "Insured Name", type: "string" },
        "Policy Number": { title: "Policy Number", type: "string" },
        "Policy Start": { title: "Policy Start", type: "string" },
        "Policy End": { title: "Policy End", type: "string" },
        "Claim Number / Claim Ref #": { title: "Claim Number / Claim Ref #", type: "string" },
        "Claim Status": { title: "Claim Status", type: "string" },
        "Date of Loss": { title: "Date of Loss", type: "string" },
        "Cause of Loss": { title: "Cause of Loss", type: "string" },
        "Description of Loss": { title: "Description of Loss", type: "string" },
        "Total Incurred": { title: "Total Incurred", type: "string" },
        "Loss Location": { title: "Loss Location", type: "string" }
      }
    })
  }
};

export const FillExcel = withRequiredAuthInfo(({ accessToken, userClass }: { accessToken: string | null, userClass: UserClass | null }) => {
  const navigate = useNavigate()
  const topComponentRef = useRef<HTMLDivElement>(null)
  const { uploadDocument, runFillExcelSOV, runFillExcelLossRun, getFillExcelLogsHistoryData, deleteDocument } = useNodeApi(accessToken);
  const [fillExcelLogs, setFillExcelLogs] = useState<FillExcelLogResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'date', direction: 'desc' });
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleColumns, setVisibleColumns] = useState(
    columnFilterOptions.map((col) => ({ ...col, visible: true }))
  );

  const lastPollTimeRef = useRef(0);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedFillType, setSelectedFillType] = useState<FillType | null>(null);

  const [isHistoryLoading, setIsHistoryLoading] = useState(true);

  const templates: Template[] = [
    { value: 'sov', name: 'SOV', isCustom: false },
    { value: 'lossRun', name: 'Loss Run', isCustom: false },
    { value: 'custom', name: 'Create your own', isCustom: true },
  ];

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const handleFillTypeClick = (template: Template) => {
    if (template.isCustom) {
      // handle navigate custom template
      const org = userClass?.getOrgs()[0];
      const isFurtherAI = org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];
      if (!isFurtherAI) {
        toast.error("Contact your admin to enable this feature.");
      }
    } else {
      setSelectedFillType(template.value);
      setIsUploadDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsUploadDialogOpen(false);
    setSelectedFillType(null);
  };

  const handleRowSelection = (id: string, isChecked: boolean) => {
    setSelectedRows(prev =>
      isChecked
        ? [...prev, id]
        : prev.filter(rowId => rowId !== id)
    );
  };

  const handleSelectAllRows = (checked: boolean) => {
    if (checked) {
      const newSelectedRows = selectedRows.slice();
      paginatedData.forEach(row => {
        if (!newSelectedRows.includes(row.id)) {
          newSelectedRows.push(row.id);
        }
      });
      setSelectedRows(newSelectedRows);
    } else {
      const currentPageIds = paginatedData.map(row => row.id);
      setSelectedRows(selectedRows.filter(id => !currentPageIds.includes(id)));
    }
  };

  const requestSort = (key: keyof SortedFillExcelData) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        if (currentConfig.direction === 'asc') {
          return { key, direction: 'desc' };
        }
        if (currentConfig.direction === 'desc') {
          return { key, direction: null };
        }
      }
      return { key, direction: 'asc' };
    });
  };

  usePolling(
    () => {
      setIsHistoryLoading(true);
      return getFillExcelLogsHistoryData();
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: true,
      lastPollTimeRef,
      onSuccess: (logs) => {
        setFillExcelLogs(logs);
        setIsHistoryLoading(false);
      },
      onError: (error) => {
        console.error("Failed to fetch Excel AI logs:", error);
        setIsHistoryLoading(false);
      }
    }
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const sortedAndFilteredData = useMemo(() => {
    return fillExcelLogs
      .map((log): SortedFillExcelData => {
        const startDate = new Date(log.created_at + "Z");
        return {
          id: log._id,
          personWhoStartedIt: "User",
          personImage: av1,
          senderName: log.owner_name || '',
          numberOfDocuments: log.user_document_ids.length,
          fillType: log.template_used,
          date: startDate.toLocaleDateString(),
          time: startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        };
      })
      .filter((item) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          item.personWhoStartedIt.toLowerCase().includes(searchLower) ||
          item.numberOfDocuments.toString().includes(searchQuery) ||
          item.fillType.toLowerCase().includes(searchLower) ||
          item.date.toLowerCase().includes(searchLower) ||
          item.time.toLowerCase().includes(searchLower)
        );
      })
      .sort((a, b) => {
        if (sortConfig.direction === null) return 0;
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [fillExcelLogs, searchQuery, sortConfig]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedAndFilteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedAndFilteredData, currentPage, rowsPerPage]);

  const renderShimmerRows = () => {
    return Array(rowsPerPage).fill(0).map((_, index) => (
      <TableRow key={`shimmer-${index}`}>
        <TableCell className={`pl-4 ${columnWidths.checkbox}`}>
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`px-4 ${columnWidths.fillType}`}>
          <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`px-4 ${columnWidths.numberOfDocuments}`}>
          <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`px-4 ${columnWidths.date}`}>
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`px-4 ${columnWidths.time}`}>
          <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`px-4 ${columnWidths.actions}`}>
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
      </TableRow>
    ));
  };

  const columnWidths = {
    checkbox: "w-[60px]",
    senderName: "w-[160px]",
    fillType: "w-[160px]",
    numberOfDocuments: "w-[140px]",
    date: "w-[120px]",
    time: "w-[120px]",
    actions: "w-[60px]"
  } as const;

  const handleColumnToggle = (columnId: string, isChecked: boolean) => {
    setVisibleColumns((prev) =>
      prev.map((col) =>
        col.id === columnId ? { ...col, visible: isChecked } : col
      )
    );
  };

  const SortableTableHead = ({ children, sortKey, className }: {
    children: React.ReactNode,
    sortKey: keyof SortedFillExcelData,
    className?: string
  }) => (
    <TableHead className={` ${className}`}>
      <Button
        variant="ghost"
        onClick={() => requestSort(sortKey)}
        className="hover:bg-transparent w-full justify-start -ml-4 px-4 h-full whitespace-nowrap"
        disabled={isHistoryLoading}
      >
        <span>{children}</span>
        <span className="ml-2">
          {sortConfig.key === sortKey && sortConfig.direction === 'asc' && (
            <ChevronUp className="h-4 w-4" />
          )}
          {sortConfig.key === sortKey && sortConfig.direction === 'desc' && (
            <ChevronDown className="h-4 w-4" />
          )}
        </span>
      </Button>
    </TableHead>
  );

  const handleRowClick = (fillExcel: SortedFillExcelData) => {
    navigate(`/excel/logs/${fillExcel.id}`)
  }

  const handleUploadComplete = async (documents: UserDocument[]) => {
    await new Promise(resolve => setTimeout(resolve, 0));

    try {
      const documentIds = documents.map(doc => doc._id);
      let result: FillExcelResponse;

      if (selectedFillType === 'sov') {
        result = await runFillExcelSOV(documentIds);
      } else {
        result = await runFillExcelLossRun(documentIds);
      }

      navigate(`/excel/logs/${result.excel_mapping_id}`);
    } catch (error) {
      console.error('Failed to initiate Excel AI:', error);
      toast.error('Failed to initiate Excel AI. Please try again.');
    }
  };

  const handleDelete = async (id: string, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await deleteLogByCategory('fill-excel', id, accessToken);
      setFillExcelLogs(prevLogs => prevLogs.filter(log => log._id !== id));
      toast.success('Successfully deleted');
    } catch (error) {
      console.error('Failed to delete excel log:', error);
      toast.error('Failed to delete');
    }
  };

  return (
    <div>
      <div className="max-w-6xl mx-auto px-2 py-8">
        <div ref={topComponentRef}>
          <h1 className="text-xl font-semibold mb-6">Excel</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4 mb-8">
            {templates.map((template) => (
              <Button
                key={template.value}
                variant="outline"
                size="lg"
                className={`
                  h-28
                  flex flex-col items-center justify-center
                  hover:bg-gray-50
                  transition-colors
                  bg-white
                  shadow-sm
                  rounded-lg
                  border border-gray-200
                  ${selectedFillType === template.value
                    ? 'ring-2 ring-blue-600 border-transparent'
                    : 'hover:border-gray-300'
                  }
                `}
                onClick={() => handleFillTypeClick(template)}
              >
                {template.isCustom ? (
                  <Plus className="h-6 w-6 text-blue-500 mb-2" />
                ) : (
                  <Sparkle className="h-6 w-6 text-blue-500 mb-2" />
                )}
                <span className="text-sm font-medium text-gray-900">
                  {template.name}
                </span>
              </Button>
            ))}
          </div>

          <div className="mt-4">
            <h1 className="text-xl font-semibold mb-4">History</h1>
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
              <div className="p-4">
                <div className="flex items-center justify-between mb-4">
                  <div className="relative">
                    <Input
                      type="text"
                      placeholder="Search"
                      className="pl-8 pr-4 py-1 w-48 h-9"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" size="sm" className="h-9 flex items-center text-gray-900 border-gray-300">
                        <Settings2 className="h-4 w-4 mr-2" />
                        View
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[200px] bg-white shadow-lg rounded-md">
                      <DropdownMenuLabel>Filter Columns</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      {columnFilterOptions.map((column) => (
                        <DropdownMenuCheckboxItem
                          key={column.id}
                          className="capitalize"
                          checked={visibleColumns.find((col) => col.id === column.id)?.visible}
                          onCheckedChange={(isChecked) => handleColumnToggle(column.id, isChecked)}
                        >
                          {column.label}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>

                <div className="overflow-x-auto rounded-md border border-gray-200">
                  <Table className="table-fixed w-full">
                    <TableHeader>
                      <TableRow>
                        <TableHead className={`${columnWidths.checkbox}`}>
                          {isHistoryLoading ? (
                            <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
                          ) : (
                            <Checkbox
                              checked={paginatedData.length > 0 && paginatedData.every(row => selectedRows.includes(row.id))}
                              onCheckedChange={handleSelectAllRows}
                            />
                          )}
                        </TableHead>
                        {visibleColumns
                          .filter((col) => col.visible)
                          .map((column) => (
                            <SortableTableHead
                              key={column.id}
                              sortKey={column.id as keyof SortedFillExcelData}
                              className={columnWidths[column.id as keyof typeof columnWidths]}
                            >
                              {column.label}
                            </SortableTableHead>
                          ))}
                        <TableHead className={`${columnWidths.actions}`} />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {isHistoryLoading ? (
                        renderShimmerRows()
                      ) : (
                        paginatedData.map((row) => (
                          <TableRow
                            key={row.id}
                            onClick={() => handleRowClick(row)}
                            className="cursor-pointer hover:bg-gray-50"
                          >
                            <TableCell className={`pl-4 ${columnWidths.checkbox}`}>
                              <Checkbox
                                checked={selectedRows.includes(row.id)}
                                onCheckedChange={(checked) =>
                                  handleRowSelection(row.id, checked as boolean)
                                }
                                onClick={(e) => e.stopPropagation()}
                              />
                            </TableCell>
                            {visibleColumns
                              .filter((col) => col.visible)
                              .map((column) => (
                                <TableCell
                                  key={column.id}
                                  className={`px-4 ${columnWidths[column.id as keyof typeof columnWidths]}`}
                                >
                                  {row[column.id as keyof SortedFillExcelData]}
                                </TableCell>
                              ))}
                            <TableCell className={`px-4 ${columnWidths.actions}`}>
                              <DropdownMenu>
                                <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
                                  <Button
                                    variant="ghost"
                                    size="sm"
                                    className="h-8 w-8 p-0 hover:bg-gray-100"
                                  >
                                    <MoreHorizontal className="h-4 w-4 text-gray-400" />
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                  className="w-40 p-1 bg-white"
                                  align="end"
                                  alignOffset={-5}
                                  sideOffset={8}
                                  forceMount
                                >
                                  <DropdownMenuItem
                                    onClick={(e) => handleDelete(row.id, e)}
                                    className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700"
                                  >
                                    <Trash2 className="mr-2 h-4 w-4" />
                                    <span>Delete</span>
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </div>

                <div className="flex justify-between items-center mt-4">
                  <p className="text-sm text-gray-500">
                    {isHistoryLoading
                      ? <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                      : `${selectedRows.length} of ${sortedAndFilteredData.length} row(s) selected.`
                    }
                  </p>
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-500">Rows per page</span>
                    <Select value={rowsPerPage.toString()} onValueChange={(value) => setRowsPerPage(Number(value))}>
                      <SelectTrigger className="w-[70px] focus:ring-0 bg-white">
                        <SelectValue placeholder={rowsPerPage.toString()} />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        <SelectItem value="5">5</SelectItem>
                        <SelectItem value="10">10</SelectItem>
                        <SelectItem value="20">20</SelectItem>
                      </SelectContent>
                    </Select>
                    <span className="text-sm text-gray-500">
                      Page {currentPage} of {Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                    </span>
                    <div className="flex space-x-1">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                      >
                        <ChevronLeft className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(sortedAndFilteredData.length / rowsPerPage)))}
                        disabled={currentPage === Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                      >
                        <ChevronRight className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isUploadDialogOpen && selectedFillType && (
        <UploadDialog
          isOpen={isUploadDialogOpen}
          onClose={handleDialogClose}
          onUploadComplete={handleUploadComplete}
          uploadDocument={uploadDocument}
          deleteDocument={deleteDocument}
          selectedTemplate={selectedFillType}
          acceptedFileTypes={fileTypeConfigs[selectedFillType as keyof typeof fileTypeConfigs]}
          category="fill_excel"
          selectedSchema={schemaConfigs[selectedFillType as keyof typeof schemaConfigs]}
        />
      )}
    </div>
  )
})
