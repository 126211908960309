import { useState, useRef, useMemo, useEffect } from "react"
import { Button } from "../ui/button"
import { Search, ChevronUp, ChevronDown, ChevronLeft, ChevronRight, MoreHorizontal, Plus, Settings2, Sparkles } from "lucide-react"
import { Input } from "../ui/input"
import { useNodeApi } from '../../hooks/useNodeApi';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table"
import { Checkbox } from "../ui/checkbox"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import av1 from '../../assets/avatars/av1.svg';
import { useNavigate, useParams } from 'react-router-dom'
import { WorkflowExecutionLogResponse, WorkflowV1, deleteLogByCategory } from '../../services/api';
import toast from 'react-hot-toast';
import { UserClass, withRequiredAuthInfo } from "@propelauth/react";
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu"
import { Trash2 } from "lucide-react"
import { WorkflowDialog } from "./WorkflowDialog"

//TODO - update the component name
import { SubmissionAIChat } from "../submissions/SubmissionAIChat"


type WorkflowExecutionData = {
  id: string;
  personWhoStartedIt: string;
  personImage: string;
  senderName: string;
  numberOfDocuments: number;
  date: string;
  time: string;
  column_data: Record<string, unknown>;
};

const getColumnFilterOptions = (workflow: WorkflowV1 | null) => {
  const defaultColumns = [
    { id: "senderName", label: "User Name" },
    { id: "numberOfDocuments", label: "Document Count" }
  ];

  const dynamicColumns = workflow?.columns?.map(col => ({
    id: `column_data.${col.name}`,
    label: col.label
  })) || [];

  const timeColumns = [
    { id: "date", label: "Date" },
    { id: "time", label: "Time" }
  ];

  return [...defaultColumns, ...dynamicColumns, ...timeColumns];
};

export const WorkflowOverview = withRequiredAuthInfo(({ accessToken, userClass }: { accessToken: string, userClass: UserClass }) => {
  const org = userClass?.getOrgs()[0];
  const navigate = useNavigate()
  const { workflowId } = useParams();

  const { uploadDocument, deleteDocument, executeWorkflowV1Request, fetchWorkflowExecutionLogs, fetchWorkflowV1 } = useNodeApi(accessToken);
  const [workflow, setWorkflow] = useState<WorkflowV1 | null>(null);
  const [workflowExecutionLogs, setWorkflowExecutionLogs] = useState<WorkflowExecutionLogResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState<{ key: keyof WorkflowExecutionData; direction: 'asc' | 'desc' | null }>({ key: 'id', direction: null });
  const [visibleColumns, setVisibleColumns] = useState<Array<{ id: string, label: string, visible: boolean }>>([]);

  const lastPollTimeRef = useRef(0);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isWorkflowDialogOpen, setIsWorkflowDialogOpen] = useState(false);
  const [isAIChatOpen, setIsAIChatOpen] = useState(false);

  const AI_ENABLED_WORKFLOW_ID = "6e9a980b-65d0-451d-a932-c06ba47bc2cb";
  const showAskAI = workflowId === AI_ENABLED_WORKFLOW_ID;

  useEffect(() => {
    setIsHistoryLoading(true);

    setWorkflowExecutionLogs([]);
    setSortConfig({ key: 'id', direction: 'desc' });
    setCurrentPage(1);

    const fetchData = async () => {
      try {
        const logs = await fetchWorkflowExecutionLogs(workflowId || '');
        setWorkflowExecutionLogs(logs);
      } catch (error) {
        console.error('Failed to fetch workflow history:', error);
        toast.error('Failed to load workflow history');
      } finally {
        setIsHistoryLoading(false);
      }
    };

    fetchData();
  }, [workflowId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowSelection = (id: string, isChecked: boolean) => {
    setSelectedRows(prev =>
      isChecked
        ? [...prev, id]
        : prev.filter(rowId => rowId !== id)
    );
  };

  const handleColumnToggle = (columnId: string, isChecked: boolean) => {
    setVisibleColumns(prev =>
      prev.map(col => (col.id === columnId ? { ...col, visible: isChecked } : col))
    );
  };

  const handleSelectAllRows = (checked: boolean) => {
    if (checked) {
      const newSelectedRows = selectedRows.slice();
      paginatedData.forEach(row => {
        if (!newSelectedRows.includes(row.id)) {
          newSelectedRows.push(row.id);
        }
      });
      setSelectedRows(newSelectedRows);
    } else {
      const currentPageIds = paginatedData.map(row => row.id);
      setSelectedRows(selectedRows.filter(id => !currentPageIds.includes(id)));
    }
  };

  const requestSort = (key: keyof WorkflowExecutionData) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        if (currentConfig.direction === 'asc') {
          return { key, direction: 'desc' };
        }
        if (currentConfig.direction === 'desc') {
          return { key, direction: null };
        }
      }
      return { key, direction: 'asc' };
    });
  };

  useEffect(() => {
    fetchWorkflowV1(workflowId || '').then(setWorkflow).catch(() => navigate('/404'));
  }, [workflowId]);  // eslint-disable-line react-hooks/exhaustive-deps

  usePolling(
    () => {
      setIsHistoryLoading(true);
      return fetchWorkflowExecutionLogs(workflowId || '');
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: true,
      lastPollTimeRef,
      onSuccess: (logs) => {
        setWorkflowExecutionLogs(logs);
        setIsHistoryLoading(false);
      },
      onError: (error) => {
        console.error("Failed to fetch workflow execution logs:", error);
        setIsHistoryLoading(false);
      }
    }
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const formatDate = (created_at: string): string => {
    const startDate = new Date(created_at + "Z");
    return startDate.toLocaleDateString();
  };

  const formatTime = (created_at: string): string => {
    const startDate = new Date(created_at + "Z");
    return startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const sortedAndFilteredData = useMemo(() => {
    return workflowExecutionLogs.map((log) => ({
      id: log._id,
      personWhoStartedIt: "User",
      personImage: av1,
      senderName: log.owner_name,
      numberOfDocuments: log.request.documents.length,
      date: formatDate(log.created_at),
      time: formatTime(log.created_at),
      column_data: log.column_data || {}
    } as WorkflowExecutionData))
      .filter((item) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          item.senderName.toLowerCase().includes(searchLower) ||
          item.date.toLowerCase().includes(searchLower) ||
          item.time.toLowerCase().includes(searchLower) ||
          item.numberOfDocuments.toString().includes(searchQuery) ||
          Object.values(item.column_data || {}).some(value =>
            value?.toString().toLowerCase().includes(searchLower)
          )
        );
      })
      .sort((a, b) => {
        if (sortConfig.direction === null) return 0;

        const keyParts = sortConfig.key.split('.');
        const aValue = keyParts.length > 1 ?
          a.column_data[keyParts[1]] :
          a[sortConfig.key as keyof typeof a];
        const bValue = keyParts.length > 1 ?
          b.column_data[keyParts[1]] :
          b[sortConfig.key as keyof typeof b];

        if (aValue == null && bValue == null) return 0;
        if (aValue == null) return 1;
        if (bValue == null) return -1;

        const aStr = String(aValue);
        const bStr = String(bValue);

        if (aStr < bStr) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aStr > bStr) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [workflowExecutionLogs, searchQuery, sortConfig]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedAndFilteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedAndFilteredData, currentPage, rowsPerPage]);

  const columnWidths = {
    checkbox: "w-[60px]",
    senderName: "w-[160px]",
    numberOfDocuments: "w-[140px]",
    date: "w-[120px]",
    time: "w-[120px]",
    actions: "w-[60px]"
  } as const;

  const SortableTableHead = ({ children, sortKey, className }: {
    children: React.ReactNode,
    sortKey: keyof WorkflowExecutionData,
    className?: string
  }) => (
    <TableHead className={` ${className}`}>
      <Button
        variant="ghost"
        onClick={() => requestSort(sortKey)}
        className="hover:bg-transparent w-full justify-start -ml-4 px-4 h-full whitespace-nowrap"
        disabled={isHistoryLoading}
      >
        <span>{children}</span>
        <span className="ml-2">
          {sortConfig.key === sortKey && sortConfig.direction === 'asc' && (
            <ChevronUp className="h-4 w-4" />
          )}
          {sortConfig.key === sortKey && sortConfig.direction === 'desc' && (
            <ChevronDown className="h-4 w-4" />
          )}
        </span>
      </Button>
    </TableHead>
  );

  const handleRowClick = (workflowExecution: WorkflowExecutionData) => {
    navigate(`/workflow-execution/${workflowExecution.id}`)
  }

  const renderShimmerContent = () => {
    return (
      <>
        <div className="flex justify-between items-center mt-2 mb-6">
          <div className="h-7 w-48 bg-gray-200 rounded animate-pulse"></div>
        </div>

        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="h-9 w-48 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-9 w-24 bg-gray-200 rounded animate-pulse"></div>
            </div>

            <div className="overflow-x-auto rounded-md border border-gray-200">
              <Table className="table-fixed w-full">
                <TableHeader>
                  <TableRow>
                    <TableHead className={`${columnWidths.checkbox}`}>
                      <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
                    </TableHead>
                    <TableHead className={`${columnWidths.senderName}`}>
                      <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                    </TableHead>
                    <TableHead className={`${columnWidths.numberOfDocuments}`}>
                      <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                    </TableHead>
                    <TableHead className={`${columnWidths.date}`}>
                      <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
                    </TableHead>
                    <TableHead className={`${columnWidths.time}`}>
                      <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
                    </TableHead>
                    <TableHead className={`${columnWidths.actions}`}>
                      <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Array(rowsPerPage).fill(0).map((_, index) => (
                    <TableRow key={`shimmer-${index}`}>
                      <TableCell className={`${columnWidths.checkbox}`}>
                        <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className={`${columnWidths.senderName}`}>
                        <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className={`${columnWidths.numberOfDocuments}`}>
                        <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className={`${columnWidths.date}`}>
                        <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className={`${columnWidths.time}`}>
                        <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className={`${columnWidths.actions}`}>
                        <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            <div className="flex justify-between items-center mt-4">
              <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
              <div className="flex items-center gap-2">
                <div className="h-4 w-40 bg-gray-200 rounded animate-pulse"></div>
                <div className="h-8 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleDelete = async (id: string, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await deleteLogByCategory('workflow-execution', id, accessToken);
      setWorkflowExecutionLogs(prevLogs => prevLogs.filter(log => log._id !== id));
      toast.success('Workflow execution log deleted successfully');
    } catch (error) {
      console.error('Failed to delete workflow execution log:', error);
      toast.error('Failed to delete workflow execution log');
    }
  };

  const handleWorkflowSend = async (message: string, documentIds: string[]) => {
    try {
      //Todo: create a new workflow
      const execute = await executeWorkflowV1Request(
        workflowId || '',
        message,
        documentIds
      );

      navigate(`/workflow-execution/${execute.workflow_execution_id}`);
    } catch (error) {
      console.error('Failed to start workflow:', error);
      toast.error('Failed to start workflow');
    }
  };

  useEffect(() => {
    if (workflow) {
      setVisibleColumns(getColumnFilterOptions(workflow).map(col => ({ ...col, visible: true })));
    }
  }, [workflow]);

  const renderCellContent = (row: WorkflowExecutionData, columnId: string): React.ReactNode => {
    if (columnId.startsWith('column_data.')) {
      const dataKey = columnId.split('.')[1];
      const value = row.column_data?.[dataKey];
      return value?.toString() || '-';
    }
    return row[columnId as keyof typeof row] as React.ReactNode;
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isAIChatOpen) {
        setIsAIChatOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [isAIChatOpen]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <div className={`flex-1 container mx-auto py-8 px-4 transition-all duration-300 ${isAIChatOpen ? 'pr-[376px]' : ''
        }`}>
        <div>


          {isHistoryLoading ? renderShimmerContent() : (
            <>
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-xl font-semibold">{workflow?.name}</h1>
                <div className="flex items-center gap-3">
                  {showAskAI && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setIsAIChatOpen(!isAIChatOpen)}
                      aria-label="Open AI chat"
                      aria-expanded={isAIChatOpen}
                    >
                      <Sparkles className="h-4 w-4 mr-2 text-blue-600" />
                      <span className="text-sm font-medium">Ask AI</span>
                    </Button>
                  )}
                  <Button
                    variant="default"
                    size="sm"
                    onClick={() => setIsWorkflowDialogOpen(true)}
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    <span className="text-sm font-medium">Start new workflow</span>
                  </Button>
                </div>
              </div>

              <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
                <div className="p-4">
                  <div className="flex items-center justify-between mb-4">
                    <div className="relative">
                      <Input
                        type="text"
                        placeholder="Search"
                        className="pl-8 pr-4 py-1 w-48 bg-white border-gray-300 text-gray-900 focus:ring-0 h-9"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                    </div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="h-9 flex items-center text-gray-900 border-gray-300"
                        >
                          <Settings2 className="h-4 w-4 mr-2" />
                          View
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end" className="w-[200px] bg-white shadow-lg rounded-md">
                        <DropdownMenuLabel>Filter Columns</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        {visibleColumns.map((column) => (
                          <DropdownMenuCheckboxItem
                            key={column.id}
                            className="capitalize"
                            checked={column.visible}
                            onCheckedChange={(isChecked) => handleColumnToggle(column.id, isChecked)}
                          >
                            {column.label}
                          </DropdownMenuCheckboxItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>

                  <div className="overflow-x-auto rounded-md border border-gray-200">
                    <Table className="table-fixed w-full">
                      <TableHeader>
                        <TableRow>
                          <TableHead className={`${columnWidths.checkbox}`}>
                            {isHistoryLoading ? (
                              <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
                            ) : (
                              <Checkbox
                                checked={paginatedData.length > 0 && paginatedData.every(row => selectedRows.includes(row.id))}
                                onCheckedChange={handleSelectAllRows}
                              />
                            )}
                          </TableHead>
                          {visibleColumns
                            .filter((col) => col.visible)
                            .map((column) => (
                              <SortableTableHead
                                key={column.id}
                                sortKey={column.id as keyof WorkflowExecutionData}
                                className={columnWidths[column.id as keyof typeof columnWidths] || 'w-[150px]'}
                              >
                                {column.label}
                              </SortableTableHead>
                            ))}
                          <TableHead className={`${columnWidths.actions}`} />
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {isHistoryLoading ? (
                          renderShimmerContent()
                        ) : (
                          paginatedData.map((row) => (
                            <TableRow
                              key={row.id}
                              onClick={() => handleRowClick(row)}
                              className="cursor-pointer hover:bg-gray-50"
                            >
                              <TableCell className={`pl-4 ${columnWidths.checkbox}`}>
                                <Checkbox
                                  checked={selectedRows.includes(row.id)}
                                  onCheckedChange={(checked) =>
                                    handleRowSelection(row.id, checked as boolean)
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </TableCell>
                              {visibleColumns
                                .filter((col) => col.visible)
                                .map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className={`px-4 ${columnWidths[column.id as keyof typeof columnWidths] || 'w-[150px]'}`}
                                  >
                                    {renderCellContent(row, column.id)}
                                  </TableCell>
                                ))}
                              <TableCell className={`px-4 ${columnWidths.actions}`}>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      className="h-8 w-8 p-0 hover:bg-gray-100"
                                    >
                                      <MoreHorizontal className="h-4 w-4 text-gray-400" />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent
                                    className="w-40 p-1 bg-white"
                                    align="end"
                                    alignOffset={-5}
                                    sideOffset={8}
                                    forceMount
                                  >
                                    <DropdownMenuItem
                                      onClick={(e) => handleDelete(row.id, e)}
                                      className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700"
                                    >
                                      <Trash2 className="mr-2 h-4 w-4" />
                                      <span>Delete</span>
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </div>

                  <div className="flex justify-between items-center mt-4">
                    <p className="text-sm text-gray-500">
                      {isHistoryLoading
                        ? <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                        : `${selectedRows.length} of ${sortedAndFilteredData.length} row(s) selected.`
                      }
                    </p>
                    <div className="flex items-center space-x-2">
                      <span className="text-sm text-gray-500">Rows per page</span>
                      <Select value={rowsPerPage.toString()} onValueChange={(value) => setRowsPerPage(Number(value))}>
                        <SelectTrigger className="w-[70px] focus:ring-0 bg-white">
                          <SelectValue placeholder={rowsPerPage.toString()} />
                        </SelectTrigger>
                        <SelectContent className="bg-white">
                          <SelectItem value="5">5</SelectItem>
                          <SelectItem value="10">10</SelectItem>
                          <SelectItem value="20">20</SelectItem>
                        </SelectContent>
                      </Select>
                      <span className="text-sm text-gray-500">
                        Page {currentPage} of {Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                      </span>
                      <div className="flex space-x-1">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                          disabled={currentPage === 1}
                        >
                          <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(sortedAndFilteredData.length / rowsPerPage)))}
                          disabled={currentPage === Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                        >
                          <ChevronRight className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={`fixed right-0 top-[84px] h-[calc(100vh-104px)] w-full md:w-[360px] transform transition-transform duration-300 ease-in-out ${isAIChatOpen ? 'translate-x-0' : 'translate-x-full'
        }`}>
        <div className="h-full mx-2 mb-4 bg-white border border-gray-200 shadow-sm rounded-lg">
          {isAIChatOpen && (
            <SubmissionAIChat
              workflowId={AI_ENABLED_WORKFLOW_ID}
              onClose={() => setIsAIChatOpen(false)}
            />
          )}
        </div>
      </div>

      <WorkflowDialog
        isOpen={isWorkflowDialogOpen}
        onClose={() => setIsWorkflowDialogOpen(false)}
        onSend={handleWorkflowSend}
        uploadDocument={uploadDocument}
        deleteDocument={deleteDocument}
        sharePointHostname={org?.orgMetadata["SHAREPOINT_HOSTNAME"] || 'https://example.com'}
      />
    </div>
  )
})
