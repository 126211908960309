import { PublicClientApplication } from "@azure/msal-browser";
import { useState, useEffect } from 'react';
import { UserDocument, UserDocumentUploadRequest } from '../../services/api';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';

interface SharePointFile {
  name: string;
  webUrl: string;
  id: string;
  parentReference: {
    driveId: string;
  };
  "@sharePoint.endpoint": string;
}

interface SharePointMessage {
  type: string;
  channelId?: string;
  data?: {
    command?: string;
    resource?: string;
    items?: SharePointFile[];
  };
  id?: string;
}

interface SharePointPayload {
  type: string;
  id?: string;
  data: {
    command?: string;
    resource?: string;
    items?: SharePointFile[];
  };
}

class SharePointService {
  private msalInstance: PublicClientApplication;
  private hostname: string;
  private initialized = false;
  private pickerWindow: Window | null = null;
  private messagePort: MessagePort | null = null;
  private accessToken: string | null = null;

  constructor(clientId: string, tenantId: string, hostname: string) {
    if (!clientId || !tenantId) {
      throw new Error("Some SharePoint configuration is missing");
    }
    this.msalInstance = new PublicClientApplication({
      auth: {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
      },
    });
    this.hostname = hostname || 'https://example.com';
  }

  private async initialize() {
    if (!this.initialized) {
      await this.msalInstance.initialize();
      await this.msalInstance.handleRedirectPromise();
      this.initialized = true;
    }
  }

  private async getToken(resource: string): Promise<string | null> {
    try {
      await this.initialize();
      const accounts = this.msalInstance.getAllAccounts();
      const account = accounts[0];

      if (!account) {
        try {
          const loginRequest = {
            scopes: [`${resource}/.default`]
          };

          const loginResponse = await this.msalInstance.loginPopup(loginRequest);
          this.msalInstance.setActiveAccount(loginResponse.account);
          return loginResponse.accessToken;
        } catch (loginError) {
          toast.error("SharePoint sign-in failed. Please try again.");
          return null;
        }
      }

      const request = {
        scopes: [`${resource}/.default`],
        account,
      };

      try {
        const response = await this.msalInstance.acquireTokenSilent(request);
        return response.accessToken;
      } catch (silentError) {
        const response = await this.msalInstance.acquireTokenPopup(request);
        return response.accessToken;
      }
    } catch (error) {
      console.error("Token acquisition failed:", error);
      toast.error("Failed to get authentication token");
      return null;
    }
  }

  async openFilePicker(): Promise<SharePointFile[]> {
    const channelId = uuid();

    this.accessToken = await this.getToken(this.hostname);

    if (!this.accessToken) {
      throw new Error("Failed to get access token");
    }

    const options = {
      sdk: "8.0",
      entry: {
        sharePoint: {
          hostname: this.hostname
        }
      },
      authentication: {},
      messaging: {
        origin: window.location.origin,
        channelId: channelId
      },
    };

    this.pickerWindow = window.open("", "Picker", "width=1080,height=680");

    if (!this.pickerWindow) {
      throw new Error("Failed to open picker window");
    }

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(options),
      locale: 'en-us'
    });

    const url = `${this.hostname}/_layouts/15/FilePicker.aspx?${queryString}`;
    const form = this.pickerWindow.document.createElement("form");
    form.setAttribute("action", url);
    form.setAttribute("method", "POST");

    const tokenInput = this.pickerWindow.document.createElement("input");
    tokenInput.setAttribute("type", "hidden");
    tokenInput.setAttribute("name", "access_token");
    tokenInput.setAttribute("value", this.accessToken);
    form.appendChild(tokenInput);

    this.pickerWindow.document.body.appendChild(form);
    form.submit();

    return new Promise((resolve) => {
      const messageHandler = async (event: MessageEvent<SharePointMessage>) => {
        if (event.source === this.pickerWindow) {
          const message = event.data;

          if (message.type === "initialize" && message.channelId === channelId) {
            this.messagePort = event.ports[0];

            const messageListener = async (event: MessageEvent<SharePointPayload>) => {
              const payload = event.data;

              switch (payload.type) {
                case "command": {
                  this.messagePort?.postMessage({
                    type: "acknowledge",
                    id: payload.id
                  });

                  switch (payload.data.command) {
                    case "authenticate": {
                      const token = await this.getToken(payload.data.resource || '');
                      this.messagePort?.postMessage({
                        type: "result",
                        id: payload.id,
                        data: {
                          result: "token",
                          token
                        }
                      });
                      break;
                    }
                    case "pick": {
                      resolve(payload.data.items || []);
                      this.pickerWindow?.close();
                      break;
                    }
                    case "close": {
                      resolve([]);
                      this.pickerWindow?.close();
                      break;
                    }
                    default:
                      break;
                  }
                  break;
                }
                default:
                  break;
              }
            };

            this.messagePort.addEventListener("message", messageListener);
            this.messagePort.start();
            this.messagePort.postMessage({
              type: "activate"
            });
          }
        }
      };

      window.addEventListener("message", messageHandler);

      const closeCheckInterval = setInterval(() => {
        if (this.pickerWindow?.closed) {
          clearInterval(closeCheckInterval);
          resolve([]);
          window.removeEventListener("message", messageHandler);
        }
      }, 1000);

      setTimeout(() => clearInterval(closeCheckInterval), 300000);
    });
  }

  public async downloadFileFromSharePoint(downloadUrl: string, fileName: string): Promise<File> {
    try {
      const response = await fetch(downloadUrl, {
        headers: {
          Authorization: `Bearer ${this.accessToken}`
        }
      });
      if (!response.ok) throw new Error('Download failed');

      const blob = await response.blob();
      return new File([blob], fileName, { type: blob.type });
    } catch (error) {
      console.error('Error downloading file:', error);
      throw new Error(`Failed to download file: ${fileName}`);
    }
  }

  public async isConnected(): Promise<boolean> {
    try {
      await this.initialize();
      const accounts = this.msalInstance.getAllAccounts();
      return accounts.length > 0;
    } catch (error) {
      return false;
    }
  }
}

export const useSharePoint = (
  uploadDocument: (
    file: File,
    request: UserDocumentUploadRequest
  ) => Promise<{ user_document_id: string; sas_url: string }>,
  setUploadedDocuments: React.Dispatch<React.SetStateAction<UserDocument[]>>,
  clientId: string,
  tenantId: string,
  hostname: string
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const sharePointService = new SharePointService(clientId, tenantId, hostname);

  useEffect(() => {
    const checkConnection = async () => {
      const connected = await sharePointService.isConnected();
      setIsConnected(connected);
    };
    checkConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAndUploadFile = async () => {
    setIsLoading(true);
    try {
      const selectedItems = await sharePointService.openFilePicker();

      if (selectedItems && selectedItems.length > 0) {
        setIsConnected(true);

        const item = selectedItems[0] as SharePointFile;

        const fileName = item.name.toLowerCase();
        const isValidFile = fileName.endsWith('.pdf') ||
          fileName.endsWith('.jpg') ||
          fileName.endsWith('.jpeg') ||
          fileName.endsWith('.png') ||
          fileName.endsWith('.xlsx');

        if (!isValidFile) {
          toast.error('Only PDF and image files are supported');
          return null;
        }

        const file = await sharePointService.downloadFileFromSharePoint(
          `${item["@sharePoint.endpoint"]}/drives/${item["parentReference"]["driveId"]}/items/${item["id"]}/content`,
          item.name,
        );

        const uploadRequest: UserDocumentUploadRequest = {
          filename: file.name,
          blob_url: "",
          origin: "sharepoint",
          category: "workflow",
          owner_uid: "",
          owner_oid: "",
          run_id: "",
        };

        const { user_document_id, sas_url } = await uploadDocument(file, uploadRequest);

        const newDocument = {
          _id: user_document_id,
          filename: file.name,
          blob_url: sas_url
        } as UserDocument;

        setUploadedDocuments(prev => [...prev, newDocument]);
        toast.success("File successfully uploaded from SharePoint");

        return newDocument;
      }
      return null;
    } catch (error) {
      console.error("SharePoint file selection/upload failed:", error);
      toast.error("Failed to process SharePoint file");
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    selectAndUploadFile,
    isLoading,
    isConnected
  };
};
