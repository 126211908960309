import { useEffect, useRef, useState } from "react"
import { Input } from "../ui/input"
import { Button } from "../ui/button"
import { Search } from "lucide-react"
import { Card, CardContent, CardTitle } from "../ui/card"
import { getIntegrationIcon } from '../../lib/iconUtils';
import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import { createOAuthConnection, CreateOAuthConnectionResponse, requestOAuthTokens, getOAuthConnections, OAuthConnection, disconnectOAuthConnection } from "../../services/api"
import { withRequiredAuthInfo } from "@propelauth/react"
import { useNavigate, useSearchParams } from "react-router-dom"

const categories = {
  "ECM": ["SharePoint"],
  "CRM": ["Salesforce", "Microsoft CRM"],
  "Policy Admin & Claims": ["Guidewire", "Majesco", "Duck Creek"],
  "AMS": ["Applied Epic", "AMS360", "EZLynx"],
  "Carriers": ["Travelers", "Progressive", "Delos", "Bamboo"]
};

type CategoryType = keyof typeof categories | "all";

export const Integrations = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  const topComponentRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate();
  const [oauthConnections, setOAuthConnections] = useState<OAuthConnection[]>([]);
  const [currentFilter, setCurrentFilter] = useState<CategoryType>("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchParams] = useSearchParams();

  const filteredApps = Object.entries(categories).reduce((acc, [category, apps]) => {
    const filteredCategoryApps = apps.filter(app =>
      app.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredCategoryApps.length > 0) {
      acc[category] = filteredCategoryApps;
    }
    return acc;
  }, {} as Record<string, string[]>);

  const asyncGetOAuthConnections = async () => {
    try {
      const connections = await getOAuthConnections(accessToken);
      setOAuthConnections(connections);
    } catch (error) {
      console.error(error);
    }
  }

  const connectSharePoint = async () => {
    const currentUrl = new URL(document.location.href);
    currentUrl.hash = "";
    currentUrl.search = "";
    const response: CreateOAuthConnectionResponse = await createOAuthConnection("sharepoint", currentUrl.href, accessToken);
    const searchParams = new URLSearchParams();
    searchParams.set("response_type", "code");
    searchParams.set("client_id", "00f28136-29e8-4d4f-88f4-9e0c9bcbec50");
    searchParams.set("redirect_uri", currentUrl.href);
    searchParams.set("scope", "offline_access Sites.Read.All profile email openid Files.ReadWrite");
    searchParams.set("access_type", "offline");
    searchParams.set("state", response.connection_id);
    const authUrl = new URL("https://login.microsoftonline.com/common/oauth2/v2.0/authorize");
    authUrl.search = searchParams.toString();
    document.location.href = authUrl.href;
  }

  const disconnectSharePoint = async () => {
    try {
      await Promise.all(
        oauthConnections
          .filter(connection => connection.provider.toLowerCase() === "sharepoint")
          .map(connection => disconnectOAuthConnection(connection.id, accessToken))
      );
      setOAuthConnections(oauthConnections.filter(connection => connection.provider.toLowerCase() !== "sharepoint"));
    } catch (error) {
      console.error(error);
    }
  }

  const connectFunctions = {
    "SharePoint": connectSharePoint
  }

  const disconnectFunctions = {
    "SharePoint": disconnectSharePoint
  }

  useEffect(() => {
    asyncGetOAuthConnections();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const requestOAuthTokensByCode = async (code: string, connectionId: string) => {
      try {
        await requestOAuthTokens(code, connectionId, accessToken);
        await asyncGetOAuthConnections();
        navigate("/integrations");
      } catch (error) {
        console.error(error);
      }
    };
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    if (code && state) {
      requestOAuthTokensByCode(code, state);
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderApps = (connectFunctions: Record<string, () => void>, disconnectFunctions: Record<string, () => void>) => {
    if (currentFilter === "all") {
      return Object.entries(filteredApps).map(([category, apps]) => (
        <div key={category} className="mb-8">
          <h3 className="text-lg font-semibold text-gray-700 mb-4">{category}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {apps.map((appName, index) => {
              const connected = oauthConnections.some(connection => connection.provider.toLowerCase() === appName.toLowerCase());
              return <AppCard key={index} appName={appName} connected={connected} onClickConnect={() => connectFunctions[appName] && connectFunctions[appName]()} onClickDisconnect={() => disconnectFunctions[appName] && disconnectFunctions[appName]()} />
            })}
          </div>
        </div>
      ));
    } else {
      const appsToRender = filteredApps[currentFilter] || [];
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {appsToRender.map((appName, index) => {
            const connected = oauthConnections.some(connection => connection.provider.toLowerCase() === appName.toLowerCase());
            return <AppCard key={index} appName={appName} connected={connected} onClickConnect={() => connectFunctions[appName] && connectFunctions[appName]()} onClickDisconnect={() => disconnectFunctions[appName] && disconnectFunctions[appName]()} />
          })}
        </div>
      );
    }
  };

  return (
    <div>
      <div className="max-w-6xl mx-auto px-2 py-8">
        <div ref={topComponentRef}>
          <h1 className="text-xl font-semibold mb-4">Integrations</h1>
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden mb-8">
            <div className="p-6">
              <div className="flex items-center mb-6 space-x-2">
                <div className="relative w-64">
                  <Input
                    type="text"
                    placeholder="Search application..."
                    className="pl-8 pr-4 py-2 w-full bg-white border-gray-300 text-gray-900 focus:ring-0 h-10"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
                <Select
                  defaultValue="all"
                  onValueChange={(value) => setCurrentFilter(value as CategoryType)}
                >
                  <SelectTrigger className="w-[180px] h-10">
                    <SelectValue placeholder="Select Category" />
                  </SelectTrigger>
                  <SelectContent className="bg-white">
                    <SelectItem value="all">All Categories</SelectItem>
                    {Object.keys(categories).map((category) => (
                      <SelectItem key={category} value={category}>
                        {category}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              {renderApps(connectFunctions, disconnectFunctions)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});

const AppCard: React.FC<{ appName: string, connected: boolean, onClickConnect: () => void, onClickDisconnect: () => void }> = ({ appName, connected, onClickConnect, onClickDisconnect }) => (
  <Card className="group relative h-40 transition-shadow">
    <CardContent className="p-0 h-full flex flex-col justify-between">
      <div className="flex items-center justify-between p-3 pb-0">
        {typeof getIntegrationIcon(appName) === 'string' ? (
          <img
            src={getIntegrationIcon(appName) as string}
            alt={`${appName} icon`}
            className="w-16 h-16 object-contain"
          />
        ) : (
          React.createElement(getIntegrationIcon(appName), {
            className: "w-16 h-16",
            'aria-label': `${appName} icon`,
            style: {
              objectFit: 'contain',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          })
        )}
        <Button
          variant="outline"
          size="sm"
          className="text-xs mr-2 mt-2"
          onClick={connected ? onClickDisconnect : onClickConnect}
        >
          {connected ? "Disconnect" : "Connect"}
        </Button>
      </div>
      <CardTitle className="text-md font-medium p-3 pt-0">
        {appName}
      </CardTitle>
    </CardContent>
  </Card>
);
