export const getFileType = (filename: string): 'pdf' | 'excel' => {
    if (!filename || typeof filename !== 'string') {
      return 'pdf';
    }

    const normalizedFilename = filename.trim().toLowerCase();

    const SUPPORTED_FORMATS = {
      excel: ['.xlsx'],
      pdf: ['.pdf']
    } as const;

    for (const [type, extensions] of Object.entries(SUPPORTED_FORMATS)) {
      if (extensions.some(ext => normalizedFilename.endsWith(ext))) {
        return type as 'pdf' | 'excel';
      }
    }

    return 'pdf';
  };
