import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface BreadcrumbsProps {
  module: string;
  path: string;
  isEmbedded?: boolean;
}

export const Breadcrumbs = ({ module, path, isEmbedded = false }: BreadcrumbsProps) => {
  const navigate = useNavigate();

  if (isEmbedded) {
    return (
      <div className="flex items-center text-md text-gray-500 mt-3">
        <span className="font-medium text-gray-900">{module}</span>
      </div>
    );
  }

  return (
    <div className="flex items-center text-md text-gray-500 mt-3">
      <span
        className="hover:text-gray-700 cursor-pointer"
        onClick={() => navigate(path)}
      >
        {module}
      </span>
      <ChevronRight className="h-4 w-4 mx-2" />
      <span className="font-medium text-gray-900">Details</span>
    </div>
  );
};
