import { useState, useRef, useEffect } from 'react';
import { SSE } from 'sse.js';
import {
  createOrUpdateNode,
  createWorkflow as apiCreateWorkflow,
  NodeData,
  WorkflowData,
  initiateCall,
  CallData,
  CallDetail,
  getCallLogs,
  getCallDetails,
  getCallLogById,
  CallLogResponse,
  CallInitiationResponse,
  streamAssistantResponse,
  AssistantRequest,
  streamAssistantResponseSSE,
  getUserDocumentSAS,
  UserDocumentUploadRequest,
  compareDocuments,
  CompareDocumentsRequest,
  CompareDocumentsResponse,
  UploadToAzureBlobStorage,
  getCompareLogs,
  CompareLogResponse,
  getCompareLogsHistory as apiGetCompareLogsHistory,
  deleteUserDocument as apiDeleteUserDocument,
  generateExcel,
  getDocumentDownloadUrl,
  GenerateCompareExcelResponse,
  DownloadDocumentResponse,
  fillExcelSOV,
  fillExcelLossRun,
  FillExcelSOVRequest,
  FillExcelLossRunRequest,
  FillExcelResponse,
  getFillExcelLog,
  getFillExcelLogsHistory,
  FillExcelLogResponse,
  generateFillExcel,
  GenerateFillExcelResponse,
  getUserDocument,
  UserDocumentDetails,
  UserDocumentSAS,
  GenerateSchemaRequest,
  generateSchema,
  ExtractRequest,
  ExtractLogResponse,
  startExtraction,
  getExtractionLog,
  getSubmissions,
  SubmissionData,
  getSubmissionEmailById,
  SubmissionEmailData,
  CreateUserSchemaRequest,
  UserSchemaResponse,
  createUserSchema,
  GetUserSchemasResponse,
  getUserSchemas,
  AuditRequest,
  AuditResponse,
  runAudit as apiRunAudit,
  AuditLogResponse,
  getAuditLogsHistory as apiGetAuditLogsHistory,
  getAuditLog as apiGetAuditLog,
  generateAuditExcel,
  getExtractLog,
  getExtractLogsHistory as apiGetExtractLogsHistory,
  deleteLogByCategory as apiDeleteLogByCategory,
  startMultiExtraction,
  ExtractMultiRequest,
  ExtractMultiLogResponse,
  getMultiExtractionLog,
  getMultiExtractLogsHistory as apiGetMultiExtractLogsHistory,
  WorkflowV1,
  createWorkflowV1,
  getWorkflowV1,
  WorkflowExecutionRequest,
  WorkflowExecutionResponse,
  executeWorkflowV1,
  getWorkflowExecutionLog,
  getWorkflowExecutionLogs,
  WorkflowExecutionLogResponse,
  postWorkkflowExecutionAskAI,
  WorkflowExecutionAskAIResponse,
  getUserSchemaById,
  deleteUserSchema,
  updateUserSchema,
  UpdateUserSchemaRequest,
  generateMultiExtractExcel,
  getWorkflowsV1,
  getUserPrompts,
  GetUserPromptsResponse,
  createUserPrompt,
  updateUserPrompt,
  deleteUserPrompt,
  UserPromptResponse,
  WorkflowExecutionDocumentsResponse,
  getWorkflowExecutionDocuments,
  postWorkflowAskAI,
  WorkflowAskAIRequest,
  WorkflowAskAIResponse,
} from '../services/api';

type FlatField = {
  name: string;
  type: string;
  description: string;
};

interface SchemaProperty {
  type: string;
  description?: string;
}

interface SchemaProperties {
  [key: string]: SchemaProperty;
}

type NestedSchema = {
  type: string;
  properties: SchemaProperties;
};

export const useNodeApi = (accessToken: string | null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCallLoading, setIsCallLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const saveNode = async (nodeData: NodeData): Promise<NodeData> => {
    setIsLoading(true);
    setError(null);
    try {
      const result: NodeData = await createOrUpdateNode(nodeData, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to save node');
      setIsLoading(false);
      throw err;
    }
  };

  const createNode = async (nodeData: NodeData): Promise<NodeData> => {
    setIsLoading(true);
    setError(null);
    try {
      const result: NodeData = await createOrUpdateNode(nodeData, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to create node');
      setIsLoading(false);
      throw err;
    }
  };

  const createWorkflow = async (workflowData: WorkflowData): Promise<WorkflowData> => {
    setIsLoading(true);
    setError(null);
    try {
      const result: WorkflowData = await apiCreateWorkflow(workflowData, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to create workflow');
      setIsLoading(false);
      throw err;
    }
  };

  const makeCall = async (callData: CallData): Promise<CallInitiationResponse> => {
    setIsCallLoading(true);
    setError(null);
    try {
      const result = await initiateCall(callData, accessToken);
      setIsCallLoading(false);
      return result;
    } catch (err) {
      setError('Failed to initiate call');
      setIsCallLoading(false);
      throw err;
    }
  };

  const fetchCallLogs = async (): Promise<CallLogResponse[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getCallLogs(accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch call logs');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchCallLogById = async (callLogId: string): Promise<CallLogResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getCallLogById(callLogId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch call log');
      setIsLoading(false);
      throw err;
    }
  };

  const getCallDetail = async (callLogId: string): Promise<CallDetail> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getCallDetails(callLogId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch call detail');
      setIsLoading(false);
      throw err;
    }
  };

  const getAssistantResponse = async (request: AssistantRequest): Promise<ReadableStream> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await streamAssistantResponse(request, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to get assistant response');
      setIsLoading(false);
      throw err;
    }
  };

  const eventSourceRef = useRef<SSE | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const getAssistantResponseSSE = (
    request: AssistantRequest,
    onDelta: (data: string) => void,
    onFinal: (data: string) => void,
    onError: (error: string) => void
  ) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    abortControllerRef.current = new AbortController();

    setIsLoading(true);
    setError(null);

    try {
      const source = streamAssistantResponseSSE(
        request,
        accessToken,
        (deltaContent) => {
          onDelta(deltaContent);
        },
        (finalContent) => {
          onFinal(finalContent);
          setIsLoading(false);
        },
        (errorMessage) => {
          setError(errorMessage);
          setIsLoading(false);
          onError(errorMessage);
        },
        abortControllerRef.current.signal
      );

      eventSourceRef.current = source;
    } catch (error) {
      setError('Failed to establish SSE connection');
      setIsLoading(false);
      onError('Failed to establish SSE connection');
    }
  };

  const cancelStreaming = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }
    setIsLoading(false);
    setError(null);
  };

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const uploadDocument = async (file: File, uploadRequest: UserDocumentUploadRequest): Promise<UserDocumentSAS> => {
    setIsLoading(true);
    setError(null);
    try {
      const sasResponse = await getUserDocumentSAS(uploadRequest, accessToken);
      await UploadToAzureBlobStorage(sasResponse.sas_url, file);
      setIsLoading(false);
      return sasResponse;
    } catch (err) {
      setError('Failed to upload document');
      setIsLoading(false);
      throw err;
    }
  };

  const runComparison = async (documentIds: string[], schema: string): Promise<CompareDocumentsResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const request: CompareDocumentsRequest = {
        documents: documentIds,
        user_schema: schema
      };

      const result = await compareDocuments(request, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to run comparison');
      setIsLoading(false);
      throw err;
    }
  };

  const getCompareLog = async (compareLogId: string): Promise<CompareLogResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getCompareLogs(compareLogId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch compare log');
      setIsLoading(false);
      throw err;
    }
  };

  const getCompareLogsHistory = async (): Promise<CompareLogResponse[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await apiGetCompareLogsHistory(accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch compare logs history');
      setIsLoading(false);
      throw err;
    }
  };

  const deleteDocument = async (documentId: string): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      await apiDeleteUserDocument(documentId, accessToken);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to delete document');
      setIsLoading(false);
      throw err;
    }
  };

  const exportCompareLog = async (compareLogId: string): Promise<string> => {
    setIsLoading(true);
    setError(null);
    try {
      const excelResponse: GenerateCompareExcelResponse = await generateExcel(compareLogId, accessToken);
      const downloadResponse: DownloadDocumentResponse = await getDocumentDownloadUrl(excelResponse.user_document_id, accessToken);
      setIsLoading(false);
      return downloadResponse.sas_url;
    } catch (err) {
      setError('Failed to export comparison');
      setIsLoading(false);
      throw err;
    }
  };

  const runFillExcelSOV = async (documentIds: string[]): Promise<FillExcelResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const request: FillExcelSOVRequest = {
        documents: documentIds,
      };
      const result = await fillExcelSOV(request, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to run Fill Excel SOV');
      setIsLoading(false);
      throw err;
    }
  };

  const runFillExcelLossRun = async (documentIds: string[]): Promise<FillExcelResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const request: FillExcelLossRunRequest = {
        documents: documentIds,
      };
      const result = await fillExcelLossRun(request, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to run Fill Excel Loss Run');
      setIsLoading(false);
      throw err;
    }
  };

  const getFillExcelLogData = async (fillExcelLogId: string): Promise<FillExcelLogResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getFillExcelLog(fillExcelLogId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch fill excel log');
      setIsLoading(false);
      throw err;
    }
  };

  const getFillExcelLogsHistoryData = async (): Promise<FillExcelLogResponse[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getFillExcelLogsHistory(accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch fill excel logs history');
      setIsLoading(false);
      throw err;
    }
  };

  const exportFillExcelLog = async (excelMappingId: string): Promise<string> => {
    setIsLoading(true);
    setError(null);
    try {
      const excelResponse: GenerateFillExcelResponse = await generateFillExcel(excelMappingId, accessToken);
      const downloadResponse: DownloadDocumentResponse = await getDocumentDownloadUrl(excelResponse.user_document_id, accessToken);
      setIsLoading(false);
      return downloadResponse.sas_url;
    } catch (err) {
      setError('Failed to export fill excel');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchUserDocument = async (documentId: string): Promise<UserDocumentDetails> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getUserDocument(documentId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch user document');
      setIsLoading(false);
      throw err;
    }
  };

  const getPdfUrl = async (documentId: string): Promise<string> => {
    setIsLoading(true);
    setError(null);
    try {
      const result: DownloadDocumentResponse = await getDocumentDownloadUrl(documentId, accessToken);
      setIsLoading(false);
      return result.sas_url;
    } catch (err) {
      setError('Failed to get PDF URL');
      setIsLoading(false);
      throw err;
    }
  };

  const generateSchemaFromPrompt = async (prompt: string): Promise<string> => {
    setIsLoading(true);
    setError(null);
    try {
      const request: GenerateSchemaRequest = {
        prompt,
        category: "excel"
      };
      const result = await generateSchema(request, accessToken);
      setIsLoading(false);
      return result.json_schema;
    } catch (err) {
      setError('Failed to generate schema');
      setIsLoading(false);
      throw err;
    }
  };

  const runMultiExtraction = async (
    documentIds: string[],
    schema: string,
    options?: { outputAsGrid?: boolean }
  ): Promise<string[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const request: ExtractMultiRequest = {
        documents: documentIds,
        user_schema: schema,
        options: options || {}
      };

      const result = await startMultiExtraction(request, accessToken);

      if (result.extract_log_id && result.extract_log_id.length > 0) {
        setIsLoading(false);
        return [result.extract_log_id];
      }

      if (result.extract_log_id) {
        setIsLoading(false);
        return [result.extract_log_id];
      }

      throw new Error('No extraction IDs found in response');
    } catch (err) {
      console.error('Extraction API error:', err);
      setError('Failed to start extraction');
      setIsLoading(false);
      throw err;
    }
  };

  const runExtraction = async (documentId: string, schema: string): Promise<string> => {
    setIsLoading(true);
    setError(null);
    try {
      const request: ExtractRequest = {
        document: documentId,
        user_schema: schema
      };
      const result = await startExtraction(request, accessToken);
      setIsLoading(false);
      return result.extract_log_id;
    } catch (err) {
      setError('Failed to start extraction');
      setIsLoading(false);
      throw err;
    }
  };

  const getExtractionResults = async (extractLogId: string): Promise<ExtractLogResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getExtractionLog(extractLogId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to get extraction results');
      setIsLoading(false);
      throw err;
    }
  };

  const getMultiExtractionResults = async (extractLogId: string): Promise<ExtractMultiLogResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getMultiExtractionLog(extractLogId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to get extraction results');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchSubmissions = async (): Promise<SubmissionData[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getSubmissions(accessToken);
      setIsLoading(false);
      return result.submissions;
    } catch (err) {
      setError('Failed to fetch submissions');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchSubmissionEmailById = async (submissionId: string): Promise<SubmissionEmailData> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getSubmissionEmailById(submissionId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch submission email');
      setIsLoading(false);
      throw err;
    }
  };

  const createTemplate = async (
    name: string,
    fields: FlatField[] | NestedSchema,
    category: string,
    options?: { outputAsGrid?: boolean }
  ): Promise<UserSchemaResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const schema = Array.isArray(fields)
        ? {
          type: "object",
          properties: fields.reduce((acc: SchemaProperties, field) => {
            if (field.name.trim()) {
              acc[field.name] = {
                type: field.type,
                description: field.description
              };
            }
            return acc;
          }, {})
        }
        : fields;

      const request: CreateUserSchemaRequest = {
        name: name,
        json_schema: JSON.stringify(schema),
        category,
        owner_uid: "",
        owner_oid: "",
        options
      };

      const result = await createUserSchema(request, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to create template');
      setIsLoading(false);
      throw err;
    }
  };

  const createPrompt = async (name: string, content: string, category: string): Promise<UserPromptResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await createUserPrompt({ name, content, category, owner_uid: "", owner_oid: "" }, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to create prompt');
      setIsLoading(false);
      throw err;
    }
  };

  const updatePrompt = async (
    promptId: string,
    name: string,
    content: string,
    category: string,
    ownerIds?: { owner_uid: string; owner_oid: string }
  ): Promise<UserPromptResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await updateUserPrompt(promptId, { name, content, category, owner_uid: ownerIds?.owner_uid || "", owner_oid: ownerIds?.owner_oid || "" }, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to update prompt');
      setIsLoading(false);
      throw err;
    }
  };


  const deletePrompt = async (promptId: string): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      await deleteUserPrompt(promptId, accessToken);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to delete prompt');
      setIsLoading(false);
      throw err;
    }
  };

  const deleteSchema = async (schemaId: string): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      await deleteUserSchema(schemaId, accessToken);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to delete schema');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchUserSchemaById = async (schemaId: string): Promise<UserSchemaResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getUserSchemaById(schemaId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch schema');
      setIsLoading(false);
      throw err;
    }
  };

  const updateSchema = async (
    schemaId: string,
    name: string,
    fields: FlatField[] | NestedSchema,
    category: string,
    options?: { outputAsGrid?: boolean },
    ownerIds?: { owner_uid: string; owner_oid: string }
  ): Promise<UserSchemaResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const schema = Array.isArray(fields)
        ? {
          type: "object",
          properties: fields.reduce((acc: SchemaProperties, field) => {
            if (field.name.trim()) {
              acc[field.name] = {
                type: field.type,
                description: field.description
              };
            }
            return acc;
          }, {})
        }
        : fields;

      const request: UpdateUserSchemaRequest = {
        name,
        json_schema: JSON.stringify(schema),
        category,
        owner_uid: ownerIds?.owner_uid || "",
        owner_oid: ownerIds?.owner_oid || "",
        options: options || undefined
      };

      const result = await updateUserSchema(schemaId, request, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to update template');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchUserSchemas = async (category: string): Promise<GetUserSchemasResponse[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getUserSchemas(category, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch user schemas');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchUserPrompts = async (category: string): Promise<GetUserPromptsResponse[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getUserPrompts(category, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch user prompts');
      setIsLoading(false);
      throw err;
    }
  };

  const runAudit = async (documentIds: string[], auditType: string, additionalContext?: string): Promise<AuditResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const request: AuditRequest = {
        documents: documentIds,
        audit_type: auditType,
        additional_context: additionalContext || null
      };

      const result = await apiRunAudit(request, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to run audit');
      setIsLoading(false);
      throw err;
    }
  };

  const getAuditLog = async (auditLogId: string): Promise<AuditLogResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await apiGetAuditLog(auditLogId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch audit log');
      setIsLoading(false);
      throw err;
    }
  };

  const getAuditLogsHistory = async (): Promise<AuditLogResponse[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await apiGetAuditLogsHistory(accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch audit logs history');
      setIsLoading(false);
      throw err;
    }
  };

  const exportAuditLog = async (auditLogId: string): Promise<string> => {
    setIsLoading(true);
    setError(null);
    try {
      const excelResponse = await generateAuditExcel(auditLogId, accessToken);
      const downloadResponse = await getDocumentDownloadUrl(excelResponse.user_document_id, accessToken);
      setIsLoading(false);
      return downloadResponse.sas_url;
    } catch (err) {
      setError('Failed to export audit');
      setIsLoading(false);
      throw err;
    }
  };

  const exportExtractLog = async (extractLogId: string): Promise<string> => {
    setIsLoading(true);
    setError(null);
    try {
      const excelResponse = await getExtractLog(extractLogId, accessToken);
      const downloadResponse = await getDocumentDownloadUrl(excelResponse.user_document_id, accessToken);
      setIsLoading(false);
      return downloadResponse.sas_url;
    } catch (err) {
      setError('Failed to export audit');
      setIsLoading(false);
      throw err;
    }
  };

  const exportMultiExtractLog = async (extractLogId: string): Promise<string> => {
    setIsLoading(true);
    setError(null);
    try {
      const excelResponse = await generateMultiExtractExcel(extractLogId, accessToken);
      const downloadResponse = await getDocumentDownloadUrl(excelResponse.user_document_id, accessToken);
      setIsLoading(false);
      return downloadResponse.sas_url;
    } catch (err) {
      setError('Failed to export extraction');
      setIsLoading(false);
      throw err;
    }
  };

  const getExtractLogsHistory = async (): Promise<ExtractLogResponse[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await apiGetExtractLogsHistory(accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch extract logs history');
      setIsLoading(false);
      throw err;
    }
  };

  const getMultiExtractLogsHistory = async (): Promise<ExtractMultiLogResponse[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await apiGetMultiExtractLogsHistory(accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch extract logs history');
      setIsLoading(false);
      throw err;
    }
  };

  const deleteLogByCategory = async (category: string, logId: string): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      await apiDeleteLogByCategory(category, logId, accessToken);
      setIsLoading(false);
    } catch (err) {
      setError(`Failed to delete ${category} log`);
      setIsLoading(false);
      throw err;
    }
  };

  const createWorkflowV1Request = async (name: string, options?: Record<string, unknown>): Promise<WorkflowV1> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await createWorkflowV1({ name, options }, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to create workflow');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchWorkflowV1 = async (workflowId: string): Promise<WorkflowV1> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getWorkflowV1(workflowId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch workflow');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchWorkflowsV1 = async (): Promise<WorkflowV1[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getWorkflowsV1(accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch workflows');
      setIsLoading(false);
      throw err;
    }
  };

  const executeWorkflowV1Request = async (
    workflowId: string,
    instruction: string,
    documentIds: string[]
  ): Promise<WorkflowExecutionResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const request: WorkflowExecutionRequest = {
        workflow_id: workflowId,
        instruction,
        documents: documentIds,
      };
      const result = await executeWorkflowV1(request, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to execute workflow');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchWorkflowExecutionLog = async (executionId: string): Promise<WorkflowExecutionLogResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getWorkflowExecutionLog(executionId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch workflow execution log');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchWorkflowExecutionLogs = async (workflowId: string): Promise<WorkflowExecutionLogResponse[]> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getWorkflowExecutionLogs(workflowId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch workflow execution logs');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchWorkkflowExecutionAskAI = async (executionId: string, question: string, documents_uploaded?: string[], documents_filtered?: string[]): Promise<WorkflowExecutionAskAIResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await postWorkkflowExecutionAskAI(executionId, { question, documents_uploaded: documents_uploaded, documents_filtered: documents_filtered }, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch workflow execution ask AI response');
      setIsLoading(false);
      throw err;
    }
  }

  const fetchWorkflowExecutionDocuments = async (workflowExecutionId: string): Promise<WorkflowExecutionDocumentsResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await getWorkflowExecutionDocuments(workflowExecutionId, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch workflow execution documents');
      setIsLoading(false);
      throw err;
    }
  };

  const fetchWorkflowAskAI = async (workflowId: string, request: WorkflowAskAIRequest): Promise<WorkflowAskAIResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await postWorkflowAskAI(workflowId, request, accessToken);
      setIsLoading(false);
      return result;
    } catch (err) {
      setError('Failed to fetch workflow ask AI response');
      setIsLoading(false);
      throw err;
    }
  };

  return {
    saveNode,
    createNode,
    createWorkflow,
    makeCall,
    getCallDetail,
    fetchCallLogById,
    fetchCallLogs,
    isLoading,
    isCallLoading,
    error,
    getAssistantResponse,
    getAssistantResponseSSE,
    cancelStreaming,
    uploadDocument,
    runComparison,
    getCompareLog,
    getCompareLogsHistory,
    deleteDocument,
    exportCompareLog,
    runFillExcelSOV,
    runFillExcelLossRun,
    getFillExcelLogData,
    getFillExcelLogsHistoryData,
    exportFillExcelLog,
    fetchUserDocument,
    getPdfUrl,
    fetchSubmissions,
    fetchSubmissionEmailById,
    createTemplate,
    createPrompt,
    updatePrompt,
    generateSchemaFromPrompt,
    runExtraction,
    runMultiExtraction,
    getExtractionResults,
    getMultiExtractionResults,
    fetchUserSchemas,
    fetchUserPrompts,
    runAudit,
    getAuditLog,
    getAuditLogsHistory,
    exportAuditLog,
    exportExtractLog,
    getExtractLogsHistory,
    getMultiExtractLogsHistory,
    deleteLogByCategory,
    createWorkflowV1Request,
    fetchWorkflowV1,
    fetchWorkflowsV1,
    executeWorkflowV1Request,
    fetchWorkflowExecutionLog,
    deleteSchema,
    fetchUserSchemaById,
    fetchWorkflowExecutionLogs,
    fetchWorkkflowExecutionAskAI,
    updateSchema,
    exportMultiExtractLog,
    fetchWorkflowExecutionDocuments,
    fetchWorkflowAskAI,
    deletePrompt,
  };
};
